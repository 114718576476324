import React, { useState, useEffect } from "react";
import { Button, Menu, Dropdown } from "antd";
import Address from "./Address";
import Balance from "./Balance";
import Wallet from "./Wallet";
import { ContrastHigh } from "../hooks";
import { useAppContext } from "../AppContextWrapper";
import { TranslationOutlined, DownOutlined } from "@ant-design/icons";
import i18next from "i18next";
import { useTranslation, Trans } from "react-i18next";
import MenuColours2 from "./MenuColours2";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function Account({ blockExplorer }) {
  const { address, connect, provider } = useAppContext();
  const { t } = useTranslation();

  const [route, setRoute] = useState();

  useEffect(() => {
    setRoute(window.location.pathname);
  }, [setRoute]);

  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "cn",
      name: "中文",
      country_code: "cn",
    },
  ];

  const menu = (
    <Menu>
      {languages.map(({ code, name, country_code }) => (
        <Menu.Item key={name} onClick={() => i18next.changeLanguage(code)}>
          {name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div>
      {address ? (
        <span>
          <div class="topRight">
            <Link
              onClick={() => {
                setRoute("/colorbasket");
              }}
              to="/colorbasket"
            >
              <ShoppingCartOutlined />
            </Link>
            <Link
              onClick={() => {
                setRoute("/colorbasket");
              }}
              to="/colorbasket"
            >
              <MenuColours2 />
            </Link>

            <Address value={address} ensProvider={provider} blockExplorer={blockExplorer} />
            <Balance address={address} provider={provider} />
            <Wallet address={address} provider={provider} ensProvider={provider} />
          </div>
        </span>
      ) : (
        <Button
          key="loginbutton"
          style={{ verticalAlign: "center", marginLeft: 8 }}
          shape="round"
          size="large"
          onClick={connect}
        >
          {t("connect_metamask")}
        </Button>
      )}
    </div>
  );
}
