import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { Colour, Footer, FooterMenuColours } from "../components";
import { Contrast } from "../hooks";
import { useAppContext } from "../AppContextWrapper";

export default function PunkColors() {
  const { basketColors, setPreviousUrl, readContracts, address, connect } = useAppContext();
  const location = useLocation();
  const mintedColorsSentence = [];
  const numbOfColors = basketColors.length;
  const history = useHistory();
  useEffect(() => {
    setPreviousUrl(location);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const defaultList = [
    { name: "e86570" },
    { name: "ff8ebe" },
    { name: "ad2160" },
    { name: "af2c7b" },
    { name: "8c0d5b" },
    { name: "690c45" },
    { name: "c13f8f" },
    { name: "9b166d" },
    { name: "a32375" },
    { name: "b03285" },
    { name: "8f0f69" },
    { name: "af38a1" },
    { name: "bd47b0" },
    { name: "cd00cb" },
    { name: "8119b7" },
    { name: "b261dc" },
    { name: "710cc7" },
    { name: "b6b4bf" },
    { name: "cac9d4" },
    { name: "dcdfea" },
    { name: "1a43c8" },
    { name: "1637a4" },
    { name: "142c7c" },
    { name: "0040ff" },
    { name: "26314a" },
    { name: "2858b1" },
    { name: "2c5195" },
    { name: "293e64" },
    { name: "1a6ed5" },
    { name: "328dfd" },
    { name: "0060c3" },
    { name: "596570" },
    { name: "cad6e1" },
    { name: "e8f4ff" },
    { name: "005580" },
    { name: "5c7f91" },
    { name: "6a8ea0" },
    { name: "3c5659" },
    { name: "80dbda" },
    { name: "75bdbd" },
    { name: "9be0e0" },
    { name: "485d5d" },
    { name: "c8fbfb" },
    { name: "2d6b62" },
    { name: "5d7975" },
    { name: "506a65" },
    { name: "8ea59f" },
    { name: "6e867f" },
    { name: "5c736b" },
    { name: "9fc0ab" },
    { name: "28b143" },
    { name: "2c9541" },
    { name: "296434" },
    { name: "83c790" },
    { name: "6aa06e" },
    { name: "5c915f" },
    { name: "229000" },
    { name: "3cc300" },
    { name: "3c6827" },
    { name: "7da269" },
    { name: "577149" },
    { name: "32412a" },
    { name: "5e7253" },
    { name: "5d8b43" },
    { name: "36462d" },
    { name: "9bbc88" },
    { name: "42503a" },
    { name: "43513b" },
    { name: "507c33" },
    { name: "486f2b" },
    { name: "6e984d" },
    { name: "5c8539" },
    { name: "596d48" },
    { name: "949593" },
    { name: "e8efc0" },
    { name: "e4eb17" },
    { name: "534c00" },
    { name: "1c1a00" },
    { name: "fff68e" },
    { name: "dcd8a4" },
    { name: "eeeab6" },
    { name: "ffd800" },
    { name: "ffd926" },
    { name: "868377" },
    { name: "8f8254" },
    { name: "ffc926" },
    { name: "ffba00" },
    { name: "a4a3a0" },
    { name: "b8b4ac" },
    { name: "b7ab98" },
    { name: "bbb3a6" },
    { name: "51360c" },
    { name: "a49681" },
    { name: "281b09" },
    { name: "4f4538" },
    { name: "b6a389" },
    { name: "dacdbb" },
    { name: "d4c8b8" },
    { name: "502f05" },
    { name: "683c08" },
    { name: "c77514" },
    { name: "794b11" },
    { name: "855114" },
    { name: "5c390f" },
    { name: "86581e" },
    { name: "85561e" },
    { name: "a66e2c" },
    { name: "352410" },
    { name: "c28946" },
    { name: "a77c47" },
    { name: "3d2f1e" },
    { name: "382d1f" },
    { name: "ae8b61" },
    { name: "463827" },
    { name: "826849" },
    { name: "796144" },
    { name: "5b4933" },
    { name: "4b3c2a" },
    { name: "765f43" },
    { name: "52422f" },
    { name: "997c59" },
    { name: "584733" },
    { name: "a48560" },
    { name: "725d43" },
    { name: "917656" },
    { name: "b69f82" },
    { name: "645849" },
    { name: "e7cba9" },
    { name: "cfbda6" },
    { name: "68461f" },
    { name: "d29d60" },
    { name: "473929" },
    { name: "dbb180" },
    { name: "5e4c37" },
    { name: "6a563f" },
    { name: "65523c" },
    { name: "a98c6b" },
    { name: "856f56" },
    { name: "d09c6e" },
    { name: "aa7b54" },
    { name: "562600" },
    { name: "723709" },
    { name: "392312" },
    { name: "52321a" },
    { name: "9b6f4d" },
    { name: "ad7e59" },
    { name: "692f08" },
    { name: "4f2c14" },
    { name: "713f1d" },
    { name: "3b210f" },
    { name: "8b532c" },
    { name: "3c2413" },
    { name: "998475" },
    { name: "897a70" },
    { name: "baaca2" },
    { name: "e65700" },
    { name: "251409" },
    { name: "552f16" },
    { name: "311b0d" },
    { name: "2d190c" },
    { name: "b0a39b" },
    { name: "763b1a" },
    { name: "825032" },
    { name: "933709" },
    { name: "ca4e11" },
    { name: "da8e66" },
    { name: "988880" },
    { name: "7f4d36" },
    { name: "b66f4e" },
    { name: "b38e7d" },
    { name: "e25b26" },
    { name: "853217" },
    { name: "5d2310" },
    { name: "4a1201" },
    { name: "5f1d09" },
    { name: "9a8e8b" },
    { name: "6b6361" },
    { name: "ff2a00" },
    { name: "c42110" },
    { name: "ddbeba" },
    { name: "ff0000" },
    { name: "d60000" },
    { name: "d60404" },
    { name: "dc1d1d" },
    { name: "711010" },
    { name: "e22626" },
    { name: "fd3232" },
    { name: "e08282" },
    { name: "e6aeae" },
    { name: "a58d8d" },
    { name: "c9b2b2" },
    { name: "5e5757" },
    { name: "ead9d9" },
    { name: "a39797" },
    { name: "afa3a3" },
    { name: "655e5e" },
    { name: "ffffff" },
    { name: "f0f0f0" },
    { name: "eeeeee" },
    { name: "dfdfdf" },
    { name: "dddddd" },
    { name: "dcdcdc" },
    { name: "d7d7d7" },
    { name: "c9c9c9" },
    { name: "c6c6c6" },
    { name: "b9b9b9" },
    { name: "b4b4b4" },
    { name: "b1b1b1" },
    { name: "999999" },
    { name: "8d8d8d" },
    { name: "6f6f6f" },
    { name: "6e6e6e" },
    { name: "636363" },
    { name: "5d5d5d" },
    { name: "595959" },
    { name: "555555" },
    { name: "515151" },
    { name: "4c4c4c" },
    { name: "353535" },
    { name: "2a2a2a" },
    { name: "000000" },
  ];
  const bits = [];
  const [punkColors, setPunkColors] = useState([]);
  console.log("punkColors", punkColors);

  if (punkColors === undefined) {
    mintedColorsSentence.push("");
  } else if (punkColors < 1) {
    mintedColorsSentence.push(
      <>
        <h1>All sold.</h1>
      </>,
    );
  } else {
    mintedColorsSentence.push(
      <>
        <h1>{punkColors} of 221 available.</h1>
      </>,
    );
  }

  const [list, updateList] = useState(defaultList);
  const { addColorToBasket } = useAppContext();

  const hexToDecimal = hex => parseInt(hex.substr(0), 16);

  const getBalance = async color => {
    const exist = await readContracts.Colorverse.exists(hexToDecimal(color));
    if (!exist) {
      bits.push(color);
      setPunkColors(bits.length);
      console.log(bits);
    }
  };

  useEffect(() => {
    for (const color of defaultList) {
      getBalance(color.name);
    }
  }, [readContracts]);

  const modalButtonsTwo = [];
  if (address) {
    if (numbOfColors > 0) {
      modalButtonsTwo.push(
        <Col span={24} className="hiddenMobile fixedBasket zindex1000">
          <div className="CheckoutFooterSelection">
            <div className="FounderSentence">
              <h3>
                <b> Got your Punk Color?</b>
                <FooterMenuColours />
              </h3>

              <br />
            </div>
            <button onClick={() => history.push("/colorbasket")} className="ButtonPrimary ">
              Go To Basket
            </button>
          </div>
        </Col>,
      );
    }
  } else {
    modalButtonsTwo.push(
      <Col xs={24} sm={18} md={18} lg={12} xl={12} xxl={12} key="modal4 hiddenMobile zindex1000">
        <div className="BigCard intro" style={{ backgroundColor: "#222222" }}>
          <span className="callToAction">Connect your wallet to buy colors.</span>

          <p className="SelectCTA">
            <button onClick={connect} className="ButtonPrimary">
              Connect
            </button>
          </p>
        </div>
      </Col>,
    );
  }

  return (
    <div key="specialcolors">
      <div key="specialcolors1" className="container" className="rowsSubpage">
        <h1>
          <b>Punkcolors.</b>{" "}
        </h1>
        <br />
        {mintedColorsSentence}

        <Row gutter={[8, 8]} className="hiddenMobile zindexbottom">
          <div className="basketColors">
            {basketColors
              .map(function (newColor) {
                return (
                  <Colour
                    hex={newColor.hex}
                    string={newColor.string}
                    contrast={newColor.contrast}
                    id={newColor.id}
                    key={newColor.id}
                  />
                );
              })
              .reverse()}
            <div className="hoverIntro" style={{ display: "none" }} />
            <div className="hoverdots" style={{ display: "none" }} />
          </div>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <div>
              {list.map(item => {
                return (
                  <div
                    onClick={e => addColorToBasket(e.target.title)}
                    className="boxSpecial"
                    value={item.name}
                    title={item.name}
                    style={{ backgroundColor: `#${item.name}`, color: `${Contrast(item.name)}` }}
                    // key={item.name}
                  >
                    #{item.name}
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
        <div className="spacingSM" />
      </div>
      <Footer basketColors={basketColors} className="zindex1000" />
      {modalButtonsTwo}

      <div className="spacerLG" />
      <div className="spacerLG" />
      <div className="spacerLG" />
      <div className="spacerLG" />
    </div>
  );
}
