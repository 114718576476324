import React from "react";
import { PageHeader } from "antd";

export default function Header() {
  return (
    <PageHeader
      title="Colorverse"
      subTitle=""
      style={{ cursor: "pointer", position: "fixed", left: 10, top: 10, padding: 10 }}
    />
  );
}
