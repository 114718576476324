import React, { useState } from "react";
import { formatEther } from "@ethersproject/units";
import { usePoller } from "eth-hooks";

export default function Balance(props) {
  const [balance, setBalance] = useState();

  const getBalance = async (address, provider) => {
    if (address && provider) {
      try {
        const newBalance = await props.provider.getBalance(props.address);
        setBalance(newBalance);
      } catch (e) {
        console.log(e);
      }
    }
  };

  usePoller(
    () => {
      getBalance(props.address, props.provider);
    },
    props.pollTime ? props.pollTime : 1999,
  );

  let floatBalance = parseFloat("0.00");

  let usingBalance = balance;

  if (typeof props.balance !== "undefined") {
    usingBalance = props.balance;
  }

  if (usingBalance) {
    const etherBalance = formatEther(usingBalance);
    parseFloat(etherBalance).toFixed(2);
    floatBalance = parseFloat(etherBalance);
  }

  const displayBalance = floatBalance.toFixed(2);

  return (
    <>
      <span
        style={{
          verticalAlign: "middle",
          fontSize: props.size ? props.size : 20,
          padding: 8,
          // cursor: "pointer",
        }}
      >
        {displayBalance}
      </span>
      <span class="ETHSymbol">ETH</span>
    </>
  );
}
