import { MenuOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../AppContextWrapper";
import Account from "./Account";
import { useTranslation, Trans } from "react-i18next";
import { PageHeader } from "antd";

const MobileMenu = ({ route, setRoute, blockExplorer }) => {
  const { provider } = useAppContext();
  const { t } = useTranslation();

  return provider ? (
    <div key="modal1">
      {/* <div className="topBlackMobile"></div> */}

      <Dropdown
        size="large"
        overlay={
          <Menu selectedKeys={[route]} style={{ borderBottom: "solid 800px #000000" }}>
            <div style={{ textAlign: "center" }}>
              <Account provider={provider} blockExplorer={blockExplorer} />
            </div>
            <div className="spacerSM" />

            <Menu.Item key="/">
              <Link
                onClick={() => {
                  setRoute("/");
                }}
                to="/"
              >
                Home
              </Link>
            </Menu.Item>

            <Menu.Item key="/search">
              <Link
                onClick={() => {
                  setRoute("/search");
                }}
                to="/search"
              >
                {t("search")}
              </Link>
            </Menu.Item>

            <Menu.Item key="/colorbasket">
              <Link
                onClick={() => {
                  setRoute("/colorbasket");
                }}
                to="/colorbasket"
              >
                {t("basket")}
              </Link>
            </Menu.Item>

            <Menu.Item key="/MyColors">
              <Link
                onClick={() => {
                  setRoute("/MyColors");
                }}
                to="/MyColors"
              >
                {t("my_colors")}
              </Link>
            </Menu.Item>

            <Menu.Item key="/NFWe">
              <Link
                onClick={() => {
                  setRoute("/NFWe");
                }}
                to="/NFWe"
              >
                NFWe
              </Link>
            </Menu.Item>

            <Menu.Item key="/founder">
              <Link
                onClick={() => {
                  setRoute("/founder");
                }}
                to="/founder"
              >
                Founder {t("founder_gallery")}
              </Link>
            </Menu.Item>
            <div className="spacerSM" />

            <Menu.Item>
              <a className="LinkBottom" href="#/termsandconditions">
                Terms
              </a>
            </Menu.Item>
            <Menu.Item>
              <a className="LinkBottom" target="_blank" href="https://twitter.com/colorverse_NFT">
                Twitter
              </a>
            </Menu.Item>
            <Menu.Item>
              <a className="LinkBottom" target="_blank" href="https://discord.com/invite/QkTgkpbfUz">
                Discord
              </a>
            </Menu.Item>
            <Menu.Item>
              <a className="LinkBottom" target="_blank" href="https://colorverse.medium.com/colorverse-io-f8b758b2c777">
                About
              </a>
            </Menu.Item>
            <Menu.Item>
              <a className="LinkBottom" target="_blank" href="https://colorverse.medium.com">
                Blog
              </a>
            </Menu.Item>
            <Menu.Item>
              <a
                target="_blank"
                className="LinkBottom"
                href="https://etherscan.io/address/0xfee27fb71ae3feeef2c11f8e02037c42945e87c4"
              >
                Contract
              </a>
            </Menu.Item>

            <div className="spacerSM" />
          </Menu>
        }
        trigger={["click"]}
      >
        <div className="mobileNav" onClick={e => e.preventDefault()}>
          Menu <MenuOutlined />
        </div>
      </Dropdown>
    </div>
  ) : (
    <div key="modal2">
      <div className="mobileNav" onClick={e => e.preventDefault()}>
        <Account blockExplorer={blockExplorer} />
      </div>
    </div>
  );
};

export default MobileMenu;
