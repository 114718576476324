import React, { useState, useEffect, useContext, Suspense } from "react";
import { notification, Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { formatEther } from "@ethersproject/units";
import detectEthereumProvider from "@metamask/detect-provider";
import { useHistory } from "react-router-dom";
import { Web3Provider } from "@ethersproject/providers";
import { BigNumber } from "ethers";
import { Transactor } from "./helpers";
import { Contrast, useGasPrice, useContractLoader, useBalance, useContractReader } from "./hooks";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import cookies from "js-cookie";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    supportedLngs: ["en", "cn"],
    fallbackLng: "en",
    detection: {
      order: ["path", "cookie", "htmlTag", "localStorage", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
    // react: { useSuspense: false },
  });

const DEBUG = true;

export const AppContext = React.createContext({
  address: "",
  provider: null,
  ethereum: null,
  booting: true,
  connect: f => f,
  basketColors: [],
  pendingColors: [],
  languages: [],
  nFWeState: "",
  setBasketColors: f => f,
  addColorToBasket: f => f,
  removeColorFromBasket: f => f,
  mintColors: f => f,
  mintNFMe: f => f,
  mintNFWe: f => f,
  changeName: f => f,
  safeTransferFrom: f => f,
  safeTransferNFweFrom: f => f,
  claimFounderToken: f => f,
  previousUrl: "/",
  bitColors: "",
  setBitColors: f => f,
  readContracts: null,
});

const hexToDecimal = hex => parseInt(hex.substr(0), 16);

const AppContextWrapper = ({ children }) => {
  const history = useHistory();
  const [address, setAddress] = useState("");
  const [booting, setBooting] = useState(true);
  const [provider, setProvider] = useState();
  const [ethereum, setEthereum] = useState();
  const [basketColors, setBasketColorsOriginal] = useState([]);
  const [pendingColors, setPendingColors] = useState([]);
  const [nFWeState, setNFWeState] = useState("Get It!");
  const [previousUrl, setPreviousUrl] = useState("/");
  const [bitColors, setBitColors] = useState([]);
  const [proposedTokenId, setProposedTokenId] = useState();

  const readContracts = useContractLoader(provider);

  const setBasketColors = (colors = []) => {
    if (Array.isArray(colors)) {
      setBasketColorsOriginal(colors);
      localStorage.setItem("basketColourData", JSON.stringify(colors));
    } else {
      console.warn(`Colors is not an array. Got ${colors}. ${typeof colors}`);
    }
  };
  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "cn",
      name: "中文",
      country_code: "cn",
    },
  ];

  const addColorToBasket = async hex => {
    const exists = await readContracts.Colorverse.exists(hexToDecimal(hex));

    if (!readContracts) return null;
    function modalAlreadyMinted() {
      const secondsToGo = 5;
      const modal = Modal.info({
        title: `What a pity, #${hex} is already sold! `,
        content: `Try another one.`,
      });

      setTimeout(() => {
        modal.destroy();
      }, secondsToGo * 1000);
    }

    if (exists) {
      modalAlreadyMinted();
      return;
    }

    function modalAlreadyAdded() {
      const secondsToGo = 5;
      const modal = Modal.info({
        title: `#${hex} is already added.`,
      });

      setTimeout(() => {
        modal.destroy();
      }, secondsToGo * 1000);
    }

    function tooManyColors() {
      const secondsToGo = 5;
      const modal = Modal.info({
        title: `Only 16 colors can be bought at once `,
      });

      setTimeout(() => {
        modal.destroy();
      }, secondsToGo * 1000);
    }

    if (basketColors.length < 16) {
      if (!basketColors.find(color => color.hex === hex)) {
        setBasketColors([
          ...basketColors,
          { hex, integrer: hexToDecimal(hex), contrast: Contrast(hex), id: Date.now() },
        ]);
        // createPinataFile(hex);
      } else {
        modalAlreadyAdded();
      }
    } else {
      tooManyColors();
    }
  };

  async function checkBasket() {
    if (!readContracts) return null;
    const checkedBaskedColors = await Promise.all(
      basketColors.map(async objectInTheBasket => [
        objectInTheBasket.hex,
        await readContracts.Colorverse.exists(hexToDecimal(objectInTheBasket.hex)),
      ]),
    );

    const notAvailableColors = checkedBaskedColors.filter(([color, exists]) => exists);

    notAvailableColors.forEach(([colorToDelete]) => {
      console.log("color:", colorToDelete, "doesn't exist anymore", basketColors);
      function handleDelete(integrer) {
        setBasketColors(basketColors.filter(color => color.integrer !== integrer));
      }
      function modalAlreadyExist(colorToDelete) {
        const secondsToGo = 5;
        const modal = Modal.info({
          title: `Sorry, #${colorToDelete} has just been sold.`,
          content: `Colors sell fast!!!!!`,
        });

        setTimeout(() => {
          modal.destroy();
        }, secondsToGo * 1000);
      }

      handleDelete(hexToDecimal(colorToDelete));
      modalAlreadyExist(colorToDelete);
    });
  }
  // führ die funktion nur aus wenn der basket etwas drin hat
  if (basketColors.length > 0) {
    checkBasket();
  }

  const accountChange = (accounts, injectedProvider) => {
    setAddress(accounts.length ? accounts[0] : "");
    setProvider(new Web3Provider(injectedProvider));
    setEthereum(injectedProvider);
  };

  const removeColorFromBasket = id => setBasketColors(basketColors.filter(color => color.id !== id));

  // only run once the first time this component is rendered
  useEffect(() => {
    (async () => {
      const injectedProvider = await detectEthereumProvider();

      if (injectedProvider) {
        injectedProvider.on("chainChanged", () => window.location.reload());
        const accounts = await injectedProvider.request({
          method: "eth_accounts",
        });

        accountChange(accounts, injectedProvider);

        injectedProvider.on("accountsChanged", newAccounts => {
          accountChange(newAccounts, injectedProvider);
        });
      }

      if (localStorage.getItem("basketColourData")) {
        try {
          setBasketColors(JSON.parse(localStorage.getItem("basketColourData")));
        } catch (error) {
          console.warn(error);
        }
      }

      setBooting(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (provider) {
        const { chainId } = await provider.getNetwork();

        if (chainId !== 1) {
          alert(`You are on the wrong chain. Please switch to Mainnet or you won't be able to mint here`);
        }
      }
    })();
  }, [provider]);

  /* 🔥 this hook will get the price of Gas from ⛽️ EtherGasStation */
  const gasPrice = useGasPrice("fast"); // 1000000000 for xdai  // The transactor wraps transactions and provides notificiations

  const tx = Transactor(provider, gasPrice);
  const writeContracts = useContractLoader(provider);

  const mintColors = async colorsToMint => {
    const cp = await readContracts.Colorverse.getPrice();

    const transactionResponse = await tx(
      writeContracts.Colorverse.mintAll(colorsToMint, { value: cp.mul(basketColors.length) }),
    );

    if (transactionResponse) {
      setPendingColors(basketColors);
      setBasketColors([]);
      history.push("/MyColors");
      await transactionResponse.wait();
      setPendingColors([]);
      notification.success({
        message: `You have successfully bought the colors!`,
      });
    }
  };

  const nextToken = useContractReader(readContracts, "NFWe", "getNextMeId");

  const antIcon = <LoadingOutlined style={{ fontSize: 20, color: "#131313" }} spin />;

  useEffect(() => {
    if (readContracts && address) {
      setProposedTokenId(nextToken);
    }
  }, [nextToken, address]);

  const mintNFMe = async (color, hash) => {
    console.log("mintMeTest", color, proposedTokenId, hash);
    const transactionResponse = await tx(writeContracts.NFWe.mintMe(color, proposedTokenId, hash));

    if (transactionResponse) {
      setNFWeState(antIcon);

      await transactionResponse.wait();
      notification.success({
        message: `You have successfully minted your NFWe token!`,
      });
      setNFWeState("You Have It!");
      window.location.reload();
    }
  };
  const mintNFWe = async (color, hash) => {
    console.log("testing", color, hash);

    const transactionResponse = await tx(writeContracts.NFWe.mintWe(color, hash));

    if (transactionResponse) {
      setNFWeState(antIcon);

      await transactionResponse.wait();
      notification.success({
        message: `You have successfully minted your NFWe token!`,
      });
      setNFWeState("You Have It!");
      window.location.reload();
    }
  };

  const changeName = async (tokenId, name) => {
    const value = await readContracts.Colorverse.getPrice();

    const transactionResponse = await tx(writeContracts.Colorverse.changeName(tokenId, name, { value }));

    if (transactionResponse) {
      await transactionResponse.wait();
      notification.success({
        message: `You have successfully changed the name!`,
      });
      return true;
    }

    return false;
  };

  const safeTransferFrom = async (owner, newAdress, tokenId) => {
    const hex = tokenId.replace("0x", "#");

    console.log(writeContracts.Colorverse);
    const transactionResponse = await tx(
      writeContracts.Colorverse["safeTransferFrom(address,address,uint256)"](owner, newAdress, tokenId),
    );

    if (transactionResponse) {
      await transactionResponse.wait();
      history.push("/MyColors");
      notification.success({
        message: `You have successfully transfered a ${hex} to ${newAdress} !`,
      });
      return true;
    }

    return false;
  };

  const safeTransferNFweFrom = async (owner, newAdress, tokenId) => {
    console.log(writeContracts.NFWe);
    const transactionResponse = await tx(
      writeContracts.NFWe["safeTransferFrom(address,address,uint256)"](owner, newAdress, tokenId),
    );

    if (transactionResponse) {
      await transactionResponse.wait();
      notification.success({
        message: `You have successfully transfered a ${tokenId} to ${newAdress} !`,
      });
      return true;
    }

    return false;
  };

  const claimFounderToken = async tokenId => {
    const transactionResponse = await tx(writeContracts.Founder.claim(tokenId));

    if (transactionResponse) {
      await transactionResponse.wait();
      notification.success({
        message: `You have successfully claimed your Founder token!`,
      });

      return true;
    }

    return false;
  };

  const connect = async () => {
    console.log("connect");
    if (ethereum) {
      await ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await ethereum.request({
        method: "eth_accounts",
      });
      if (accounts.length) {
        setAddress(accounts[0]);
      }
    } else {
      alert("No Metamask found");
    }
  };
  const loadingMarkup = (
    <div>
      <h2>loading...</h2>
    </div>
  );
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find(l => l.code === currentLanguageCode);

  return (
    <AppContext.Provider
      value={{
        address,
        connect,
        booting,
        provider,
        basketColors,
        pendingColors,
        nFWeState,
        bitColors,
        setBitColors,
        addColorToBasket,
        removeColorFromBasket,
        mintColors,
        mintNFMe,
        mintNFWe,
        changeName,
        safeTransferFrom,
        safeTransferNFweFrom,
        claimFounderToken,
        previousUrl,
        setPreviousUrl,
        readContracts,
      }}
    >
      <Suspense fallback={loadingMarkup}>{children}</Suspense>
      {/* {children} */}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

export default AppContextWrapper;
