import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Card, Row, Spin, Tabs, Col, Form, Modal, Button } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { LoadingOutlined, HeartOutlined } from "@ant-design/icons";
import { getAddress } from "@ethersproject/address";
import { BigNumber } from "ethers";
import { AddressInput } from "../components";
import { useAppContext } from "../AppContextWrapper";
import { useContractReader, Contrast, ContrastHigh, Complement, useGasPrice } from "../hooks";
import { useTranslation, Trans } from "react-i18next";

const { TabPane } = Tabs;

/*
 * hasColors
 * hasMes
 * hasMeWes
 * hasWes
 * unchlaimedWes
 */

const TransferCreateForm = ({ transferToken, onCancel }) => {
  const [transferToAddresses, setTransferToAddresses] = useState({});
  const id = 0;

  const { safeTransferNFweFrom, address } = useAppContext();

  return (
    <Modal
      visible={!!transferToken}
      title="Transfer your color to an address"
      okText="Transfer"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        safeTransferNFweFrom(address, transferToAddresses[id], transferToken);
        return false;
      }}
    >
      <AddressInput
        placeholder="transfer to address"
        value={transferToAddresses[id]}
        onChange={newValue => {
          const update = {};
          update[id] = newValue;
          setTransferToAddresses({ ...transferToAddresses, ...update });
        }}
      />
    </Modal>
  );
};

export default function NFWe() {
  const { address, readContracts, mintNFMe, mintNFWe, nFWeState, connect, provider } = useAppContext();
  const { setPreviousUrl } = useAppContext();
  const [ownedColors, setOwnedColors] = useState([]);
  const [collection, setCollection] = useState([]);
  const [nFWeTokens, setNFWeTokens] = useState([]);
  const [nFMe, setNFMe] = useState([]);
  const [nFWe, setNFWe] = useState([]);
  const [nFMeWe, setNFMeWe] = useState([]);
  const [nFWeBalance, setNFWeBalance] = useState(BigNumber.from(0));
  const [colorNames, setColorNames] = useState({});
  const location = useLocation();
  const [transferToken, setTransferToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nFWeTokensTotal, setNFWeTokensTotal] = useState([]);
  const [thirdWe, setThirdWe] = useState([]);
  const [baseColor, setBaseColor] = useState("");
  const [complementColor, setComplementColor] = useState("");
  const [incomingMeColors, setIncomingMeColors] = useState([]);
  const [incomingWeColors, setIncomingWeColors] = useState([]);
  const [weDetails, setWeDetails] = useState([]);
  const [totalOfMe, setTotalOfMe] = useState("");
  const [edition, setEdition] = useState("");
  const hexToDecimal = hex => parseInt(hex.substr(1), 16);
  const { t } = useTranslation();

  function decimalToHex(decimal) {
    return "#" + (decimal + Math.pow(16, 6)).toString(16).slice(-6).toUpperCase();
  }

  // const hasColors = ownedColors.length > 0;
  // const hasMes = nFMe.length > 0;
  // const hasMeWes = nFMeWe.length > 0;
  // const hasWes = nFWe.length > 0;
  // const hasUnchlaimedWes = incomingWeColors && incomingWeColors.length > 0;

  const weWithoutMes = nFWe.filter(
    ({ NFWeColor }) => !nFMe.find(({ NFMeColor }) => Complement(NFWeColor).replace("#", "") === NFMeColor),
  );

  const nextMeId = useContractReader(readContracts, "NFWe", "getNextMeId");
  const totalSupplyMeWe = useContractReader(readContracts, "NFWe", "totalSupply");

  useEffect(() => {
    if (nextMeId !== undefined) {
      setTotalOfMe(nextMeId.toNumber());
      setEdition((nextMeId.toNumber() + 1) / 2);
    }
  }, [nextMeId]);

  useEffect(() => {
    setBaseColor("#dddddd");
    setComplementColor("#333333");
  }, []);

  useEffect(() => {
    setIncomingWeColors(incomingMeColors.map(Complement));
  }, [incomingMeColors]);

  const history = useHistory();
  useEffect(() => {
    setPreviousUrl(location);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (readContracts && address) {
      readContracts.Colorverse.getCollectionArray(address).then(c => {
        setOwnedColors(c.map(b => `000000${b._hex.replace("0x", "")}`.slice(-6).toUpperCase()).map(cc => `${cc}`));

        ownedColors.forEach(hex =>
          readContracts.Colorverse.tokenNameById(`0x${hex}`).then(name =>
            setColorNames({ ...colorNames, [hex]: name }),
          ),
        );
      });

      // readContracts.NFWe.getMeTokenIds(address).then(setMeTokenIds);
      readContracts.NFWe.balanceOf(address).then(setNFWeBalance);
    }
  }, [readContracts, address]);

  useEffect(() => {
    if (readContracts && address) {
      readContracts.NFWe.getCollectionArray(address).then(c => {
        setCollection(c);
        setLoading(true);
      });
    }
  }, [readContracts, address]);

  useEffect(() => {
    collection.forEach(async item => {
      if (item.toNumber() % 2 === 0) {
        const svgWe = await readContracts.NFWe.renderSVG(item);
        const [colorId] = await readContracts.NFWe.getColorIdForWeId(item);
        const ownerOfMe = await readContracts.NFWe.getMeOwner(colorId);
        const NFWeColor = `000000${colorId._hex.toString().replace("0x", "")}`.slice(-6).toUpperCase();
        setNFWe(t => [
          ...t,
          { NFWeColor, item, ownerOfMe, svgWe: svgWe.replace(/([^a-z0-9='<.,>:#;|{}&%/\- ]+)/gi, "") },
        ]);
      } else {
        const svgMe = await readContracts.NFWe.renderSVG(item);
        const [colorId] = await readContracts.NFWe.getColorIdForMeId(item);
        const NFMeColor = `000000${colorId._hex.toString().replace("0x", "")}`.slice(-6).toUpperCase();
        setNFMe(t => [...t, { NFMeColor, item, svgMe: svgMe.replace(/([^a-z0-9='<.,>:#;|{}&%/\- ]+)/gi, "") }]);
      }
    });
  }, [collection]);

  useEffect(() => {
    collection.forEach(async item => {
      for (let i = 0; i < collection.length; i++) {
        if (item.add(1).eq(collection[i]) && collection[i].toString() % 2 == 0) {
          const svgMe = await readContracts.NFWe.renderSVG(item);
          const svgWe = await readContracts.NFWe.renderSVG(collection[i]);

          setNFMeWe(t => [
            ...t,
            {
              svgMe: svgMe.replace(/([^a-z0-9='<.,>:#;|{}&%/\- ]+)/gi, ""),
              svgWe: svgWe.replace(/([^a-z0-9='<.,>:#;|{}&%/\- ]+)/gi, ""),
            },
          ]);
        }
      }
    });
  }, [collection]);

  useEffect(() => {
    setIncomingMeColors([]);
    ownedColors.forEach(async hex => {
      const complementHex = `0x${Complement(hex).replace("#", "")}`;
      const DecimalHex = hexToDecimal(`#${hex}`);
      const meForThisColorExist = await readContracts.NFWe.getMeIdForColorId(complementHex);
      if (meForThisColorExist.gte(1)) {
        const getWeIdForColorId = await readContracts.NFWe.getWeIdForColorId(DecimalHex);
        if (getWeIdForColorId == 0) {
          const weEdition = (meForThisColorExist.toNumber() + 1) / 2;
          let ownerOfMe = await readContracts.NFWe.getMeOwner(hexToDecimal(`#${hex}`));
          ownerOfMe = ownerOfMe.toString();
          setIncomingMeColors(t => [...t, `#${hex}`]);
          setWeDetails(t => [...t, { ownerOfMe: ownerOfMe.toString(), hex: `#${hex}`, weEdition }]);
        }
      }
    });
  }, [ownedColors, address]);

  // useEffect(() => {
  //   if (readContracts) {
  //     Array(nFWeBalance.toNumber())
  //       .fill(0)
  //       .map(async (_, index) => {
  //         const tokenId = await readContracts.NFWe.tokenOfOwnerByIndex(address, index);
  //         const svg = await readContracts.NFWe.renderSVG(tokenId);
  //         let meColor = await readContracts.NFWe.getColorIdForMeId(tokenId);
  //         meColor = meColor[0]._hex.toString();
  //         meColor = `000000${meColor.replace("0x", "")}`.slice(-6).toUpperCase();

  //         setNFWeTokens(t => [...t, { meColor, tokenId, svg: svg.replace(/([^a-z0-9='<.,>:#;|{}&%/\- ]+)/gi, "") }]);
  //         // console.log("svg", svg, meColor.toString().replace(",true", ""), nFWeTokens);
  //       });
  //   }
  // }, [nFWeBalance, address]);

  useEffect(() => {
    if (readContracts && totalSupplyMeWe) {
      const NFWeTokensTotalPromises = Array(totalSupplyMeWe.toNumber())
        .fill(0)
        .map(async (_, index) => {
          const tokenId = await readContracts.NFWe.tokenByIndex(index);
          const svg = await readContracts.NFWe.renderSVG(tokenId);
          const owner = await readContracts.NFWe.ownerOf(tokenId);

          return { tokenId: tokenId.toString(), owner, svg: svg.replace(/([^a-z0-9='<.,>:#;|{}&%/\- ]+)/gi, "") };
        });

      Promise.all(NFWeTokensTotalPromises).then(setNFWeTokensTotal);
    }
  }, [totalSupplyMeWe, readContracts]);

  const axios = require("axios");
  const FormData = require("form-data");
  const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
  const pinataApiKey = "0997d354cae13f776524";
  const pinataSecretApiKey = "2aa5134500d4f20dc0c456336cd20a0eec812228ae003a353e09b2a48cb751bc";

  function mintingStartMe(baseColor, complementColor) {
    const svgString = `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 350 350' width='100%' height='100%'><style>.small { font: bold 12px sans-serif; } .big { font: bold 180px sans-serif; }</style><rect width='100%' height='100%' style='fill: ${baseColor}'></rect><text text-anchor='middle' x='175px' y='220px' class='big' style='fill: ${complementColor}'>Me,</text><text text-anchor='end' x='88%' y='84%' class='small' style='fill: ${complementColor}'>Me is the complement of We.</text><text text-anchor='end' x='88%' y='88%' class='small' style='fill: ${complementColor}'>${edition}/512</text></svg>`;

    const blob = new Blob([svgString], { type: "image/svg+xml" });
    const fileOfBlob = new File([blob], `NFWe_${baseColor.replace("#", "")}_Me.svg`);

    const data = new FormData();
    const pinataOptions = JSON.stringify({
      cidVersion: 1,
    });
    data.append("file", fileOfBlob);
    data.append("pinataOptions", pinataOptions);

    const mytest = axios
      .post(url, data, {
        maxContentLength: "Infinity", // this is needed to prevent axios from erroring out with large files
        headers: {
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          pinata_api_key: pinataApiKey,
          pinata_secret_api_key: pinataSecretApiKey,
        },
      })
      .then(function (response) {
        // handle response here
        console.log(
          "!!!!Success!!!! ",
          response.data.IpfsHash,
          // `https://gateway.pinata.cloud/ipfs/${response.data.IpfsHash}`,
          hexToDecimal(baseColor),
          edition,
        );
        // console.log("svgString", svgString, response.data);

        mintNFMe(hexToDecimal(baseColor), response.data.IpfsHash);
      })
      .catch(function (error) {
        // handle error here
        console.log("!!!!ERROR!!!! ", error);
      });
  }
  function mintingStartWe(incomingMeColor, complementColor, weEdition) {
    const svgString = `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 350 350' width='100%' height='100%'><style>.small { font: bold 12px sans-serif; } .big { font: bold 180px sans-serif; }</style><rect width='100%' height='100%' style='fill: ${incomingMeColor}'></rect><text text-anchor='middle' x='175px' y='220px' class='big' style='fill: ${complementColor}'>We.</text><text text-anchor='end' x='88%' y='84%' class='small' style='fill: ${complementColor}'>Me is the complement of We.</text><text text-anchor='end' x='88%' y='88%' class='small' style='fill: ${complementColor}'>${weEdition}/512</text></svg>`;

    const blob = new Blob([svgString], { type: "image/svg+xml" });
    const fileOfBlob = new File([blob], `NFWe_${incomingMeColor.replace("#", "")}_We.svg`);

    const data = new FormData();
    const pinataOptions = JSON.stringify({
      cidVersion: 1,
    });
    data.append("file", fileOfBlob);
    data.append("pinataOptions", pinataOptions);

    const mytest = axios
      .post(url, data, {
        maxContentLength: "Infinity", // this is needed to prevent axios from erroring out with large files
        headers: {
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          pinata_api_key: pinataApiKey,
          pinata_secret_api_key: pinataSecretApiKey,
        },
      })
      .then(function (response) {
        // handle response here
        console.log(
          "!!!!Success!!!! ",
          response.data.IpfsHash,
          // `https://gateway.pinata.cloud/ipfs/${response.data.IpfsHash}`,
          hexToDecimal(baseColor),
          edition,
        );
        // console.log("svgString", svgString, response.data);

        mintNFWe(hexToDecimal(incomingMeColor), response.data.IpfsHash);
      })
      .catch(function (error) {
        // handle error here
        console.log("!!!!ERROR!!!! ", error);
      });
  }

  function SvgComponentMe({ props, localBaseColor }) {
    const localComplementColor = Complement(localBaseColor);
    return (
      <div>
        <svg viewBox="0 0 1024 1024" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" {...props}>
          <rect fill={localBaseColor} width="1024" height="1024" />
          <g className="st1">
            <path
              fill={localComplementColor}
              d="M363.9,267.6h114v379H404V390.2c0-7.4,0.1-17.7,0.3-31c0.2-13.3,0.3-23.5,0.3-30.7l-71.8,318.1h-77
                l-71.3-318.1c0,7.2,0.1,17.4,0.3,30.7c0.2,13.3,0.3,23.6,0.3,31v256.4h-73.8v-379h115.2l69,298L363.9,267.6z"
            />
            <path
              fill={localComplementColor}
              d="M727.9,372c19.5,8.8,35.7,22.6,48.4,41.5c11.5,16.7,18.9,36,22.3,57.9c2,12.9,2.8,31.4,2.4,55.6H596.9
                c1.1,28.1,10.9,47.8,29.3,59.1c11.2,7,24.7,10.5,40.4,10.5c16.7,0,30.3-4.3,40.7-12.9c5.7-4.6,10.7-11.1,15.1-19.3h74.8
                c-2,16.6-11,33.5-27.2,50.7c-25.1,27.3-60.3,40.9-105.4,40.9c-37.3,0-70.2-11.5-98.7-34.5c-28.5-23-42.8-60.4-42.8-112.2
                c0-48.6,12.9-85.8,38.6-111.7c25.7-25.9,59.2-38.9,100.2-38.9C686.3,358.8,708.3,363.2,727.9,372z M618.2,435.3
                c-10.4,10.7-16.9,25.1-19.5,43.4H725c-1.3-19.5-7.8-34.2-19.5-44.3c-11.7-10.1-26.2-15.1-43.5-15.1
                C643.2,419.3,628.6,424.6,618.2,435.3z"
            />
            <g>
              <path
                fill={localComplementColor}
                d="M836.1,706.5c14.8-4.1,25.7-10.9,32.7-20.5c7-9.6,11-23,12-40.2h-44.7v-76.6h79.5v66.3
		c0,11.7-1.5,23.8-4.6,36.5c-3.1,12.6-8.9,23.6-17.3,32.8c-9.1,9.9-19.8,17.4-32.1,22.4c-12.3,5-20.8,7.6-25.4,7.6V706.5z"
              />
            </g>
          </g>{" "}
        </svg>
      </div>
    );
  }

  function SvgComponentWe({ props, localBaseColor }) {
    const localComplementColor = Complement(localBaseColor);

    return (
      <div>
        <svg viewBox="0 0 1024 1024" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" {...props}>
          <rect fill={localBaseColor} width="1024" height="1024" />
          <g className="st1">
            <path
              fill={localComplementColor}
              d="M140.2,267.6l50,217.1l10.9,60.4l10.9-59.2l42.8-218.3h83.6l45,217L395,545l11.7-58.1l50.5-219.4h80.6
		l-106.6,379h-75.8l-45.8-221.7l-13.4-73.3l-13.4,73.3l-45.8,221.7h-73.6l-107.5-379H140.2z"
            />
            <path
              fill={localComplementColor}
              d="M753.1,372c19.5,8.8,35.7,22.6,48.4,41.5c11.5,16.7,18.9,36,22.3,57.9c2,12.9,2.8,31.4,2.4,55.6H622.2
		c1.1,28.1,10.9,47.8,29.3,59.1c11.2,7,24.7,10.5,40.4,10.5c16.7,0,30.3-4.3,40.7-12.9c5.7-4.6,10.7-11.1,15.1-19.3h74.8
		c-2,16.6-11,33.5-27.2,50.7c-25.1,27.3-60.3,40.9-105.4,40.9c-37.3,0-70.2-11.5-98.7-34.5c-28.5-23-42.8-60.4-42.8-112.2
		c0-48.6,12.9-85.8,38.6-111.7c25.7-25.9,59.2-38.9,100.2-38.9C711.6,358.8,733.6,363.2,753.1,372z M643.5,435.3
		c-10.4,10.7-16.9,25.1-19.5,43.4h126.3c-1.3-19.5-7.8-34.2-19.5-44.3c-11.7-10.1-26.2-15.1-43.5-15.1
		C668.4,419.3,653.9,424.6,643.5,435.3z"
            />
            <path fill={localComplementColor} d="M862.1,569.7H940v76.9h-77.9V569.7z" />
          </g>
        </svg>
      </div>
    );
  }

  function SvgComponentWeBlur({ props, localBaseColor }) {
    const localComplementColor = Complement(localBaseColor);
    return (
      <div>
        <svg viewBox="0 0 1024 1024" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" {...props}>
          <defs>
            <filter id="f1" x="0" y="0">
              <feGaussianBlur in="SourceGraphic" stdDeviation="20" />
            </filter>
          </defs>

          <rect fill={localBaseColor} width="1024" height="1024" />
          <g filter="url(#f1)">
            <rect x="19" y="63" className="st6" width="6" height="6" />

            <g className="st1">
              <path
                fill={localComplementColor}
                d="M140.2,267.6l50,217.1l10.9,60.4l10.9-59.2l42.8-218.3h83.6l45,217L395,545l11.7-58.1l50.5-219.4h80.6
    l-106.6,379h-75.8l-45.8-221.7l-13.4-73.3l-13.4,73.3l-45.8,221.7h-73.6l-107.5-379H140.2z"
              />
              <path
                fill={localComplementColor}
                d="M753.1,372c19.5,8.8,35.7,22.6,48.4,41.5c11.5,16.7,18.9,36,22.3,57.9c2,12.9,2.8,31.4,2.4,55.6H622.2
    c1.1,28.1,10.9,47.8,29.3,59.1c11.2,7,24.7,10.5,40.4,10.5c16.7,0,30.3-4.3,40.7-12.9c5.7-4.6,10.7-11.1,15.1-19.3h74.8
    c-2,16.6-11,33.5-27.2,50.7c-25.1,27.3-60.3,40.9-105.4,40.9c-37.3,0-70.2-11.5-98.7-34.5c-28.5-23-42.8-60.4-42.8-112.2
    c0-48.6,12.9-85.8,38.6-111.7c25.7-25.9,59.2-38.9,100.2-38.9C711.6,358.8,733.6,363.2,753.1,372z M643.5,435.3
    c-10.4,10.7-16.9,25.1-19.5,43.4h126.3c-1.3-19.5-7.8-34.2-19.5-44.3c-11.7-10.1-26.2-15.1-43.5-15.1
    C668.4,419.3,653.9,424.6,643.5,435.3z"
              />
              <path fill={localComplementColor} filter="url(#f1)" d="M862.1,569.7H940v76.9h-77.9V569.7z" />
            </g>
          </g>
        </svg>
      </div>
    );
  }

  // *************** ******************** ******************** ********************* *******************
  // *************** ******************** ******************** ********************* *******************
  // *************** ******************** ******************** ********************* *******************
  // *************** ******************** ******************** ********************* *******************
  // *************** ******************** ******************** ********************* *******************

  return (
    <>
      <div key="yourcolors" className="rowsSubpage">
        <div className="container">
          <h1>
            <b>NFWe.</b>
          </h1>
          <div className="spacerSM" />
          <h3>
            <b>Me</b> {t("is_the_complement_of")} <b>We</b>.
          </h3>
          <br />
          <br /> {t("read_the_full_story_here")}&nbsp;
          <a
            style={{ borderBottom: "solid 2px #ffffff" }}
            target="_blank"
            href="https://colorverse.medium.com/nfwe-the-non-fungible-we-245ef7395a43"
          >
            {t("here")}
          </a>
          .
          {weDetails.map(({ ownerOfMe, hex, weEdition }) => (
            <>
              <div className="spacerLG" />
              <Row
                gutter={[24, 24]}
                style={{
                  backgroundColor: `${hex}`,
                  padding: "24px",
                  border: `solid 2px ${ContrastHigh(hex.replace("#", ""))}`,
                }}
              >
                <Col xs={24} sm={6}>
                  <div
                    style={{
                      border: `solid 2px ${hex}`,
                    }}
                  >
                    <SvgComponentMe localBaseColor={Complement(hex)} />
                  </div>
                </Col>
                <Col xs={24} sm={12}>
                  <h4 style={{ color: `${ContrastHigh(hex.replace("#", ""))}` }}>
                    <a
                      style={{
                        borderBottom: `solid 2px ${ContrastHigh(hex.replace("#", ""))}`,
                        color: `${ContrastHigh(hex.replace("#", ""))}`,
                      }}
                      href={`https://opensea.io/accounts/${ownerOfMe}`}
                      className="Detail"
                      target="_blank"
                    >
                      {ownerOfMe.substr(0, 6) + "..." + ownerOfMe.substr(-4)}
                    </a>
                    &nbsp;{t("minted_the_me_in_one_of_your_colors_complementary")}.
                    <br />
                    {t("this_is_why_youre_able_to_claim_a_we")}
                  </h4>
                  <div className="spacerSM" />
                  <button
                    onClick={() => {
                      // console.log("weEdition", weEdition);
                      mintingStartWe(hex, Complement(hex), weEdition);
                    }}
                    className="ButtonContrast"
                    style={{
                      border: `solid 2px ${ContrastHigh(hex.replace("#", ""))}`,
                      backgroundColor: "rgba(0,0,0,0)",
                      color: `${ContrastHigh(hex.replace("#", ""))}`,
                    }}
                  >
                    {t("get_the")} We!
                  </button>
                </Col>

                <Col xs={24} sm={6}>
                  <div
                    style={{
                      border: `solid 2px ${Complement(hex)}`,
                    }}
                  >
                    <SvgComponentWe localBaseColor={hex} />
                  </div>
                </Col>
              </Row>
            </>
          ))}
          <div className="spacerSM" />
          <Tabs defaultActiveKey="1" left="true" className="relative ">
            <TabPane tab="Yours" key="1">
              {nFMeWe.map(({ svgMe, svgWe }) => (
                <>
                  <div className="spacerSM" />
                  <Row gutter={[24, 24]}>
                    <Col xs={0} sm={4} xl={6} />
                    <Col xs={24} sm={8} xl={6}>
                      <div width="100%">
                        <div dangerouslySetInnerHTML={{ __html: svgMe }} />
                      </div>
                    </Col>

                    <Col xs={24} sm={8} xl={6}>
                      <div width="100%">
                        <div dangerouslySetInnerHTML={{ __html: svgWe }} />
                      </div>
                    </Col>

                    <Col xs={0} sm={4} xl={6} />

                    <Col xs={0} sm={4} xl={6} />

                    <Col xs={24} sm={16} xl={12}>
                      <h3>
                        <Trans i18nKey="congrats">
                          <b>Congrats!!!</b> You’re one of the <b>rare</b> owners of a complete <b>Me, We.</b>
                        </Trans>

                        <HeartOutlined style={{ fontSize: "48px", color: "#ffffff" }} />
                      </h3>
                      <div className="spacerLG" />
                    </Col>
                    <Col xs={0} sm={4} xl={6} />
                  </Row>
                </>
              ))}
              {nFMeWe.length === 0 ? (
                <>
                  <div className="spacerLG" />
                  {nFMe.map(({ NFMeColor, item, svgMe }) => (
                    <Row gutter={[24, 24]} key={NFMeColor}>
                      <Col xs={0} sm={4} xl={6} />
                      <Col xs={24} sm={8} xl={6}>
                        <div width="100%">
                          <div dangerouslySetInnerHTML={{ __html: svgMe }} />
                        </div>

                        <div style={{ marginTop: "16px" }} />

                        <h3>
                          <b>{t("you_got_your")} "Me".</b>
                        </h3>
                      </Col>

                      <Col xs={24} sm={8} xl={6}>
                        <SvgComponentWeBlur localBaseColor={Complement(NFMeColor)} />

                        <button
                          onClick={() => history.push(`/${Complement(NFMeColor).replace("#", "")}`)}
                          className="ButtonSecondarySM"
                          key="getyoursbutton"
                        >
                          {t("find_your")} "We"
                        </button>
                        <div className="spacerSM" />
                      </Col>
                      <Col xs={0} sm={4} xl={6} />
                    </Row>
                  ))}
                </>
              ) : (
                ""
              )}

              {weWithoutMes.map(({ svgWe, NFMeColor, item }) => (
                <Row gutter={[24, 24]} key={NFMeColor}>
                  <Col xs={0} sm={4} xl={6} />
                  <Col xs={24} sm={8} xl={6}>
                    <div width="100%">
                      <div dangerouslySetInnerHTML={{ __html: svgWe }} />
                    </div>
                  </Col>

                  <Col xs={24} sm={8} xl={6}>
                    <h3>
                      <b>{t("you_now_have_the_we_of_somebody_else")}</b>
                    </h3>
                    <div className="spacerSM" />
                    <button
                      className="ButtonSecondarySM"
                      onClick={() => {
                        setTransferToken(item);
                      }}
                    >
                      {t("Transfer")}
                    </button>
                  </Col>
                  <Col xs={0} sm={4} xl={6} />
                </Row>
              ))}

              {loading === true ? (
                <>
                  {nFMe < 1 ? (
                    <>
                      <Row gutter={[24, 8]} className="tabfontsize">
                        {ownedColors.length === 0 ? (
                          <>
                            <Col sm={24} md={10}>
                              <div>
                                {513 - edition > 0 ? (
                                  <h4>
                                    {t("if_you_own_a_colorverse_token,_your_address_can_mint_exactly_one_nft")}
                                    <div className="spacerSM" />
                                    {t(
                                      "the_we_token_then_can_be_claimed_by_any_address_that_owns_the_complement_color",
                                    )}
                                    <div className="spacerSM" />
                                    <b>
                                      {513 - edition} {t("of_512_me_are_available")}
                                    </b>
                                  </h4>
                                ) : (
                                  <>
                                    <h4>
                                      <b>{t("all_nfwe_are_gone")}</b>
                                      <br /> {t("all_nfwe_are_gone")} &nbsp;
                                      <a
                                        target="_blank"
                                        href="https://twitter.com/colorverse_NFT"
                                        style={{ borderBottom: "solid 2px #ffffff" }}
                                      >
                                        Twitter
                                      </a>
                                      &nbsp; {t("to_hear_about_upcoming_colorverse_projects")}
                                    </h4>
                                  </>
                                )}
                              </div>
                            </Col>
                            <Col sm={0} md={1} />

                            <Col sm={24} md={13}>
                              <Row gutter={[12, 12]}>
                                <Col sm={24} md={24}>
                                  <h4>
                                    <b>{t("you_need_to_own_a_color_to_get_a_me")}</b>
                                  </h4>

                                  <div className="spacerXS" />
                                  <div>
                                    <div className="colorTile" onClick={() => history.push("/search")}>
                                      <div
                                        className="colorTileColor"
                                        style={{
                                          backgroundColor: `#666666`,
                                          border: `solid 2px #979797`,
                                          textAlign: "center",
                                        }}
                                      >
                                        ?
                                      </div>
                                    </div>
                                  </div>
                                </Col>

                                <Col xs={24} sm={12}>
                                  <SvgComponentMe localBaseColor={baseColor} />
                                </Col>
                                <Col xs={24} sm={12}>
                                  <SvgComponentWe localBaseColor={complementColor} />
                                </Col>
                              </Row>
                            </Col>
                            <hr style={{ color: "#ffffff" }} />
                          </>
                        ) : (
                          <>
                            <Col sm={24} md={10}>
                              <div>
                                {513 - edition > 0 ? (
                                  <h4>
                                    {t("if_you_own_a_colorverse_token,_your_address_can_mint_exactly_one_nft")}
                                    <div className="spacerSM" />
                                    {t(
                                      "the_we_token_then_can_be_claimed_by_any_address_that_owns_the_complement_color",
                                    )}
                                    <div className="spacerSM" />
                                    <b>
                                      {513 - edition} {t("of_512_me_are_available")}
                                    </b>
                                  </h4>
                                ) : (
                                  <>
                                    <h4>
                                      <b>{t("all_nfwe_are_gone")}</b>
                                      <br /> {t("all_nfwe_are_gone")}&nbsp;
                                      <a
                                        target="_blank"
                                        href="https://twitter.com/colorverse_NFT"
                                        style={{ borderBottom: "solid 2px #ffffff" }}
                                      >
                                        Twitter
                                      </a>
                                      &nbsp; {t("to_hear_about_upcoming_colorverse_projects")}
                                    </h4>
                                  </>
                                )}

                                <div className="spacerSM" />
                              </div>
                            </Col>
                            <Col sm={0} md={1} />

                            <Col sm={24} md={13}>
                              <Row gutter={[12, 12]}>
                                <Col sm={24} md={24}>
                                  <h4>
                                    <b>{t("pick_one_of_your_colors_as_a_background")}</b>
                                  </h4>

                                  <div className="spacerXS" />
                                  <div>
                                    {ownedColors.map(value => (
                                      <div
                                        className="colorTile"
                                        key={value}
                                        onClick={() => {
                                          setBaseColor(`#${value}`);
                                          setComplementColor(decimalToHex(16777215 - hexToDecimal(`#${value}`)));
                                        }}
                                      >
                                        <div
                                          className="colorTileColor"
                                          style={{
                                            backgroundColor: `#${value}`,
                                            fontSize: 16,
                                            border: `solid 2px ${Contrast(value)}`,
                                          }}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                </Col>

                                <Col xs={24} sm={12}>
                                  <SvgComponentMe localBaseColor={baseColor} />

                                  {baseColor !== "#dddddd" && 513 - edition > 0 ? (
                                    <button
                                      className="ButtonPrimary"
                                      onClick={() => mintingStartMe(baseColor, complementColor)}
                                    >
                                      {nFWeState}
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </Col>

                                <Col xs={24} sm={12}>
                                  {baseColor !== "#dddddd" ? (
                                    <>
                                      <SvgComponentWeBlur
                                        stdDeviation="7 9"
                                        style={{
                                          opacity: 0.7,
                                        }}
                                        localBaseColor={complementColor}
                                      />

                                      <button
                                        onClick={() => history.push(`/${complementColor.replace("#", "")}`)}
                                        className="ButtonSecondarySM"
                                        key="getyoursbutton"
                                      >
                                        {t("lookup_complement")}
                                      </button>
                                    </>
                                  ) : (
                                    <SvgComponentWe localBaseColor={complementColor} />
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </>
                        )}
                      </Row>
                    </>
                  ) : (
                    <>
                      <div className="spacerLG" />
                      <div className="spacerLG" />
                      <div className="spacerLG" />
                      <div className="spacerLG" />
                      <h4>{t("each_address_can_mint_exactly_one_me_nft_it_seems_that_you_have_got_yours")}</h4>
                    </>
                  )}
                </>
              ) : (
                <>
                  {address ? (
                    <span>
                      <h3>{t("loading")}</h3>
                    </span>
                  ) : (
                    <>
                      <h4>{t("please_connect_metamask_to_load_the_page")}</h4>

                      <br />
                      <div className="spacerSM" />

                      <button onClick={connect} className="ButtonSecondarySM" key="connectbutton">
                        {t("connect_metamask")}
                      </button>
                    </>
                  )}
                </>
              )}
            </TabPane>

            {/* ******** GALLERY ******** */}

            <TabPane tab={t("nfwe_gallery")} key="2">
              <Row gutter={[0, 8]} className="tabfontsize">
                <div className="spacerLG" />

                {
                  nFWeTokensTotal.map(({ tokenId, owner, svg }) => (
                    <Col xs={24} sm={12} xl={6}>
                      <div style={{ marginRight: "48px" }} key={tokenId}>
                        <div dangerouslySetInnerHTML={{ __html: svg }} />
                        {t("owner")}:
                        <a href={`https://opensea.io/accounts/${owner}`} className="Detail" target="_blank">
                          {owner.substr(0, 6) + "..." + owner.substr(-4)}
                        </a>
                        <br />
                        <div className="spacerLG" />
                      </div>
                    </Col>
                  ))
                  // .reverse()
                }
              </Row>
              <div className="spacerLG" />
            </TabPane>
          </Tabs>
        </div>
        <div className="spacerLG" />
        <div className="spacerLG" />

        <div className="spacerLG" />
      </div>

      <TransferCreateForm
        transferToken={transferToken}
        onCancel={() => {
          setTransferToken(false);
        }}
      />
    </>
  );
}
