import React from "react";
import ParticleField from "react-particles-webgl";

const ItemsStart = () => {
  /**
   * Tim Ellenberger
   *
   * docs@tim-soft/react-particles-webgl
   */

  const config = {
    showCube: false,
    dimension: "3D",
    velocity: 0,
    boundaryType: "passthru",
    antialias: false,
    direction: {
      xMin: -1,
      xMax: 0.6,
      yMin: -1,
      yMax: -0.8,
      zMin: -1,
      zMax: -0.6,
    },
    lines: {
      visible: false,
    },
    particles: {
      colorMode: "rainbow",
      transparency: 0.7,
      shape: "circle",
      boundingBox: "canvas",
      count: 100,
      minSize: 81,
      maxSize: 33,
      visible: true,
    },
  };

  return (
    <div key="test1" className="colorverse noscroll zindexbottom">
      <ParticleField config={config} />
    </div>
  );
};

export default ItemsStart;
