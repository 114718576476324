import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Card, Row, Spin, Tabs } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ConsoleSqlOutlined, LoadingOutlined } from "@ant-design/icons";
import { getAddress } from "@ethersproject/address";
import { BigNumber } from "ethers";
import { useAppContext } from "../AppContextWrapper";
import { ContrastHigh } from "../hooks";
import { useTranslation, Trans } from "react-i18next";

const { TabPane } = Tabs;

export default function MyColors() {
  const { address, readContracts } = useAppContext();
  const { pendingColors, claimFounderToken, setPreviousUrl } = useAppContext();
  const [farben, setFarben] = useState([]);
  const [claimableIds, setClaimableIds] = useState([]);
  const [founderBalance, setFounderBalance] = useState(BigNumber.from(0));
  const [colorNames, setColorNames] = useState({});
  const [founderTokens, setFounderTokens] = useState([]);
  const location = useLocation();
  const { t } = useTranslation();

  const history = useHistory();
  useEffect(() => {
    setPreviousUrl(location);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (readContracts && address) {
      readContracts.Colorverse.getCollectionArray(address).then(c => {
        setFarben(c.map(b => `000000${b._hex.replace("0x", "")}`.slice(-6).toUpperCase()).map(cc => `#${cc}`));

        farben.forEach(hex =>
          readContracts.Colorverse.tokenNameById(`0x${hex}`).then(name =>
            setColorNames({ ...colorNames, [hex]: name }),
          ),
        );
      });

      readContracts.Founder.getClaimableIds(address).then(setClaimableIds);
      readContracts.Founder.balanceOf(address).then(setFounderBalance);
    }
  }, [readContracts, address]);

  useEffect(() => {
    if (readContracts && address) {
      Array(founderBalance.toNumber())
        .fill(0)

        .map(async (_, index) => {
          const tokenId = await readContracts.Founder.tokenOfOwnerByIndex(address, index);
          const svg = await readContracts.Founder.renderSVG(tokenId);
          setFounderTokens(t => [...t, { tokenId, svg: svg.replace(/([^a-z0-9='<.>:#;|{}&%/\- ]+)/gi, "") }]);
        });
    }
  }, [founderBalance, address]);

  const claim = async id => {
    const result = await claimFounderToken(id);

    if (result) {
      // eslint-disable-next-line no-underscore-dangle
      setClaimableIds(claimableIds.filter(claimableId => claimableId._hex !== id._hex));
      setFounderBalance(founderBalance.add(1));
    }
  };

  function SvgComponent(props) {
    return (
      <svg width={350} height={350} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fill="#fff" d="M0 0h350v350H0z" />
        <path fill="#16f0b5" d="M11 11h82v82H11z" />
        <path fill="#c081a7" d="M93 11h82v82H93z" />
        <path fill="#93402a" d="M175 11h82v82h-82z" />
        <path fill="#4f899c" d="M257 11h82v82h-82z" />
        <path fill="#feb75d" d="M11 93h82v82H11z" />
        <path fill="#521de9" d="M93 93h82v82H93z" />
        <path fill="#763300" d="M175 93h82v82h-82z" />
        <path fill="#31480b" d="M257 93h82v82h-82z" />
        <path fill="#119908" d="M11 175h82v82H11z" />
        <path fill="#524907" d="M93 175h82v82H93z" />
        <path fill="#ca5960" d="M175 175h82v82h-82z" />
        <path fill="#b2ae5f" d="M257 175h82v82h-82z" />
        <path fill="#6a0d1a" d="M11 257h82v82H11z" />
        <path fill="#d6bb02" d="M93 257h82v82H93z" />
        <path fill="#e9c8f2" d="M175 257h82v82h-82z" />
        <path fill="#5190e0" d="M257 257h82v82h-82z" />
      </svg>
    );
  }

  const antIcon = <LoadingOutlined style={{ fontSize: 48, color: "#131313" }} spin />;

  function saveSvg(svg, name) {
    const svgData = svg;
    const preface = "";
    const svgBlob = new Blob([preface, svgData], { type: "image/svg+xml;charset=utf-8" });
    const svgUrl = URL.createObjectURL(svgBlob);
    const downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  return farben.length === 0 ? (
    <>
      <div key="yourcolors" className="rowsSubpage">
        <div className="container">
          {pendingColors.length ? (
            <>
              <h2>
                <b>{t("pending_colors")}</b>
              </h2>
              <div className="spacerSM" />
              <Row gutter={[8, 8]}>
                <div className="basketColors">
                  {pendingColors.map(p => (
                    <div className="cardPending">
                      <div
                        className="cardColorPending"
                        style={{ backgroundColor: `#${p.hex}`, fontSize: 16, color: `${ContrastHigh(p.hex)}` }}
                      >
                        <Spin indicator={antIcon} />
                      </div>
                      <div className="cardInfoPending">#{p.hex}</div>
                    </div>
                  ))}
                </div>
              </Row>
            </>
          ) : (
            ""
          )}
          <h2>
            <b>{t("it_can_take_a_little_while_to_load")} </b>
          </h2>
          <div className="spacerSM" />

          <h3>{t("if_you_own_no_colors_get_some")}</h3>

          <div className="spacerSM" />
          <button onClick={() => history.push("/colorbasket")} className="ButtonSecondary">
            {t("get_colors")}
          </button>
        </div>
      </div>
    </>
  ) : (
    <>
      <div key="yourcolors" className="rowsSubpage">
        <div className="container">
          {pendingColors.length ? (
            <>
              <h2>
                <b>{t("pending_colors")}</b>
              </h2>
              <div className="spacerSM" />
              <Row gutter={[8, 8]}>
                <div className="basketColors">
                  {pendingColors.map(p => (
                    <div className="cardPending">
                      <div
                        className="cardColorPending"
                        style={{ backgroundColor: `#${p.hex}`, fontSize: 16, color: `${ContrastHigh(p.hex)}` }}
                      >
                        <Spin indicator={antIcon} />
                      </div>
                      <div className="cardInfoPending">#{p.hex}</div>
                    </div>
                  ))}
                </div>
              </Row>
            </>
          ) : (
            ""
          )}
          <h2>
            <b>{t("my_colors")}.</b>
          </h2>

          <Tabs defaultActiveKey="1" left="true" className="relative ">
            <TabPane tab={t("colors")} key="1">
              <Row gutter={[0, 8]} className="tabfontsize">
                <div className="spacerSM" />
                <h4>
                  <b>
                    {farben.length} {t("colors_owned")}.
                  </b>
                </h4>
                <div className="spacerLG" />
                <br />
                <div className="basketColors">
                  {farben.map(value => (
                    <Link to={`/${value.replace("#", "")}`} key={value}>
                      <div className="card" key={value}>
                        <div className="cardColor" style={{ backgroundColor: value, fontSize: 16 }} />
                        <div className="cardInfo">{value}</div>
                        <div className="cardMeta">{colorNames[value]}</div>
                      </div>
                    </Link>
                  ))}
                </div>
              </Row>
            </TabPane>
            <TabPane tab={t("founder_token")} key="2">
              <Row gutter={[0, 8]} className="tabfontsize">
                <div className="spacerLG" />

                <h4>
                  {t("anyone_minting_16_colors_in_a_transaction_can_claim_a_founder_nft")}
                  <div className="spacerSM" />
                  <b>
                    {founderBalance.add(claimableIds.length).toString()} {t("founder_token_registered")}
                  </b>
                  {founderBalance.toString()} {t("anyone_minting_16_colors_in_a_transaction_can_claim_a_founder_nft")}.
                </h4>

                <div className="spacerSM" />

                <div className="spacerSM" />
                {claimableIds.map(id => (
                  <div>
                    <div style={{ marginRight: "48px" }}>
                      <SvgComponent className="mono" />
                      <div className="spacerXS" />

                      <button className="ButtonPrimary" onClick={() => claim(id)}>
                        {t("claim_ounder_token")} {id.toString()}
                      </button>
                    </div>
                    <div className="spacerLG" />
                    <div className="spacerLG" />
                  </div>
                ))}

                {founderTokens.map(({ tokenId, svg }) => (
                  <div style={{ marginRight: "48px" }}>
                    <div dangerouslySetInnerHTML={{ __html: svg }} />
                    Founder {tokenId.toString()}
                    <p>
                      <br />
                      <a onClick={() => saveSvg(svg, `Founder_${tokenId}.svg`)} className="underlineLink">
                        {t("download_svg")}
                      </a>
                    </p>
                    <div className="spacerLG" />
                  </div>
                ))}

                <div className="spacerLG" />
              </Row>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
}
