import React from "react";

import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

import { HashRouter } from "react-router-dom";
import { TwitterOutlined } from "@ant-design/icons";

import AppContextWrapper from "./AppContextWrapper";
import AppSwitch from "./components/AppSwitch";

import "antd/dist/antd.css";
import "./dark.css";
import "./App.css";

const client = new ApolloClient({
  uri: "https://api.studio.thegraph.com/query/17380/colorverse/v1.0",
  cache: new InMemoryCache(),
});

function App() {
  return (
    <div className="App" key="App1">
      <HashRouter>
        <ApolloProvider client={client}>
          <AppContextWrapper>
            <AppSwitch />
          </AppContextWrapper>
        </ApolloProvider>
      </HashRouter>

      <div
        className="hiddenMobile"
        style={{ zIndex: "100", position: "fixed", textAlign: "right", right: 0, bottom: 10, padding: 10 }}
      >
        <a className="LinkBottom" href="#/termsandconditions">
          Terms
        </a>

        <a
          target="_blank"
          className="LinkBottom"
          href="https://etherscan.io/address/0xfee27fb71ae3feeef2c11f8e02037c42945e87c4"
        >
          Contract
        </a>
        <a target="_blank" className="LinkBottom" href="https://colorverse.medium.com/colorverse-io-f8b758b2c777">
          About
        </a>
        <a target="_blank" className="LinkBottom" href="https://colorverse.medium.com/">
          Blog
        </a>
        <a target="_blank" className="LinkBottom" href="https://twitter.com/colorverse_NFT">
          <TwitterOutlined style={{ fontSize: "20px" }} /> Twitter
        </a>
        <a target="_blank" className="LinkBottom" href="https://discord.com/invite/QkTgkpbfUz" id="LogoDiscord">
          <img src="Discord-Logo-White.png" width="24" className="LogoDiscord" />
          Discord
        </a>
      </div>
    </div>
  );
}

export default App;
