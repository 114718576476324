import React, { useCallback, useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router";
import { Row, Col, Button, Modal, Form, Input, Checkbox, List, Card, notification } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { AddressInput, Address } from "../components";
import { useContractLoader, useGasPrice } from "../hooks";
import { Transactor } from "../helpers";
import { useTranslation } from "react-i18next";

import "antd/dist/antd.css";

import ContrastHigh from "../helpers/ContrastHigh";

import { useAppContext } from "../AppContextWrapper";

const CollectionCreateForm = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      title={t("give_a_name_to_your_color")}
      okText="OK"
      cancelText={t("give_a_name_to_your_color")}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            onCreate(values);
          })
          .catch(info => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item name="newName" label="New Name" rules={[{ required: true, message: "Please input a name!" }]}>
          <Input />
        </Form.Item>

        <Form.Item name="termsChecked" className="collection-create-form_last-form-item">
          {t("by_clicking_ok_you_agree_to_the")}
          <a href="/termsandconditions"> Terms and Conditions</a>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const TransferCreateForm = ({ visible, onCreate, onCancel, owner, tokenId }) => {
  const [formTransfer] = Form.useForm();
  const [transferToAddresses, setTransferToAddresses] = useState({});
  const id = 0;
  const gasPrice = useGasPrice("fast"); // 1000000000 for xdai
  const { t } = useTranslation();

  const { safeTransferFrom } = useAppContext();

  return (
    <Modal
      visible={visible}
      title={t("transfer_your_color_to_an_address")}
      okText={t("transfer")}
      cancelText={t("cancel")}
      onCancel={onCancel}
      onOk={() => {
        safeTransferFrom(owner, transferToAddresses[id], tokenId);

        return false;
      }}
    >
      <AddressInput
        placeholder={t("transfer_to_an_address")}
        value={transferToAddresses[id]}
        onChange={newValue => {
          const update = {};
          update[id] = newValue;
          setTransferToAddresses({ ...transferToAddresses, ...update });
        }}
      />
    </Modal>
  );
};

export default function ColorDetail() {
  const { hex } = useParams();
  const history = useHistory();
  const { previousUrl, changeName, address, readContracts, addColorToBasket, basketColors } = useAppContext();
  const [visible, setVisible] = useState(false);
  const [transferVisible, setTransferVisible] = useState(false);
  const [owner, setOwner] = useState("");
  const [name, setName] = useState(null);
  const { t } = useTranslation();

  const onCreate = async ({ newName }) => {
    await changeName(`0x${hex}`, newName);
    setVisible(false);
  };

  // const onTransfer = async ({ newAddress }) => {
  //   await safeTransferFrom(owner, newAddress, `0x${hex}`);
  //   setTransferVisible(false);
  // };

  useEffect(() => {
    if (readContracts) {
      readContracts.Colorverse.ownerOf(`0x${hex}`).then(
        tokenOwner => {
          setOwner(tokenOwner);
        },
        error => {
          if (error.message.includes("owner query for nonexistent token")) {
            setOwner("");
          } else {
            throw error;
          }
        },
      );

      readContracts.Colorverse.tokenNameById(`0x${hex}`).then(
        tokenName => {
          setName(tokenName);
        },
        error => {
          if (error.message.includes("owner query for nonexistent token")) {
            setOwner("");
          } else {
            throw error;
          }
        },
      );
    }
  }, [readContracts]);

  const onClose = () => {
    history.replace(previousUrl || "/");
  };

  const addOrDelete = [];

  if (!basketColors.find(color => color.hex === hex)) {
    addOrDelete.push(
      <button
        key={hex}
        onClick={() => {
          addColorToBasket(hex);
          history.push("/colorbasket");
        }}
        className="ButtonContrast"
        style={{
          border: `solid 2px ${ContrastHigh(hex)}`,
          backgroundColor: "rgba(0,0,0,0)",
          color: `${ContrastHigh(hex)}`,
        }}
      >
        {t("add_color")}
      </button>,
    );
  } else {
    addOrDelete.push(
      <>
        <h4 style={{ color: `${ContrastHigh(hex)}` }}>{t("you_added_it_to_your_basket")}</h4>
        <div className="spacerSM" />
        <button
          onClick={() => {
            history.push("/colorbasket");
          }}
          className="ButtonContrast"
          style={{
            border: `solid 2px ${ContrastHigh(hex)}`,
            backgroundColor: "rgba(0,0,0,0)",
            color: `${ContrastHigh(hex)}`,
          }}
        >
          {t("go_to_basket")}
        </button>
      </>,
    );
  }

  const hexToDecimal = hex => parseInt(hex.substr(0), 16);

  return (
    <div className="overlay" key="ColorDetail">
      <div className="container">
        <div className="ColorDetail" style={{ backgroundColor: `#${hex}` }}>
          <div className="ScrollableContainer">
            <CloseOutlined className="closeBig" onClick={onClose} style={{ color: `${ContrastHigh(hex)}` }} />
            <h1>
              <b style={{ color: `${ContrastHigh(hex)}` }}>#{hex.toUpperCase()}</b>
              <br />

              <b style={{ color: `${ContrastHigh(hex)}` }}>{name}</b>
            </h1>
            <div className="spacerXS" />

            {address.toLowerCase() === owner.toLowerCase() && address !== "" && (
              <button
                type="primary"
                onClick={() => {
                  setVisible(true);
                }}
                className="ButtonContrast"
                style={{
                  border: `solid 2px ${ContrastHigh(hex)}`,
                  backgroundColor: "rgba(0,0,0,0)",
                  color: `${ContrastHigh(hex)}`,
                }}
              >
                {t("change_name_for_5")}
              </button>
            )}

            <div>
              <CollectionCreateForm
                visible={visible}
                onCreate={onCreate}
                onCancel={() => {
                  setVisible(false);
                }}
              />
            </div>
            <div className="spacerSM" />
            <b>
              {owner === null && owner !== "" && <div style={{ color: `${ContrastHigh(hex)}` }}>Loading...</div>}
              {owner !== null && owner !== "" && (
                <>
                  <p className="DetailCaption" style={{ color: `${ContrastHigh(hex)}` }}>
                    {t("this_color_is_currently_owned_by_address")}
                  </p>
                  <br />

                  <a
                    href={`https://opensea.io/accounts/${owner}`}
                    className="Detail"
                    style={{ color: `${ContrastHigh(hex)}` }}
                    target="_blank"
                  >
                    {owner.substr(0, 6) + "..." + owner.substr(-4)}
                  </a>
                  <div className="spacerSM" />
                </>
              )}

              {address.toLowerCase() === owner.toLowerCase() && address !== "" && (
                <button
                  type="primary"
                  onClick={() => {
                    setTransferVisible(true);
                  }}
                  className="ButtonContrast"
                  style={{
                    border: `solid 2px ${ContrastHigh(hex)}`,
                    backgroundColor: "rgba(0,0,0,0)",
                    color: `${ContrastHigh(hex)}`,
                  }}
                >
                  {t("transfer_color")}
                </button>
              )}

              <TransferCreateForm
                visible={transferVisible}
                onCancel={() => {
                  setTransferVisible(false);
                }}
                tokenId={`0x${hex}`}
                owner={owner}
              />

              {owner !== null && owner === "" && (
                <div>
                  <h1 className="DetailCaption">
                    <b style={{ color: `${ContrastHigh(hex)}` }}>5$</b>
                  </h1>
                  <div className="spacerSM" />

                  {addOrDelete}
                </div>
              )}
            </b>
          </div>
          <div className="TokenID">
            <p className="DetailCaption" style={{ color: `${ContrastHigh(hex)}` }}>
              {t("token_id")}
            </p>
            <br />
            <p style={{ color: `${ContrastHigh(hex)}` }}>{hexToDecimal(hex)}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
