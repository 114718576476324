import React from "react";
import { useAppContext } from "../AppContextWrapper";
import AppConnected from "./AppConnected";
import AppIntro from "./AppIntro";
import ItemsStart from "./ItemsStart";

const AppSwitch = () => {
  const { provider, booting } = useAppContext();

  if (booting)
    return (
      <div className="container mainpage" key="intro">
        <ItemsStart />
      </div>
    );

  return provider ? <AppConnected /> : <AppIntro />;
};

export default AppSwitch;
