import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import { QuestionOutlined, CloseOutlined } from "@ant-design/icons";
import { FooterBasket } from ".";
import { useAppContext } from "../AppContextWrapper";

function FounderSmall(props) {
  const { basketColors } = useAppContext();
  const [addModalOpen, setAddModalOpen] = useState(false);

  const handleAddClick = () => {
    setAddModalOpen(true);
  };
  const handleClose = () => {
    setAddModalOpen(false);
  };
  let modal = "none";
  if (addModalOpen === true) {
    modal = "flex";
  }

  const founder = [];

  if (basketColors.length > 0) {
    if (basketColors.length === 1) {
      founder.push(<div style={{ backgroundColor: `#${basketColors[0].hex}` }} className="founderColumn" />);
    }
  }

  const table = [];
  var numb = 16 - basketColors.length;

  for (let i = 0; i < numb; i++) {
    table.push(
      <div
        style={{ backgroundColor: "#222222", color: "#333333", border: "solid 1px #333333" }}
        className="founderColumn "
      />,
    );
  }

  const founderSmall = [];

  if (basketColors.length > 0) {
    if (basketColors.length === 1) {
      founderSmall.push(<div style={{ backgroundColor: `#${basketColors[0].hex}` }} className="founderColumn" />);
    }
  }

  const tableSmall = [];
  var numb = 16 - basketColors.length;

  for (let i = 0; i < numb; i++) {
    tableSmall.push(
      <div
        style={{ backgroundColor: "#131313", color: "#222222", border: "solid 1px #000000" }}
        className="founderSmallColumn "
      />,
    );
  }

  return (
    <>
      <Col onClick={handleAddClick} xs={12} sm={8} md={6} lg={6} xl={4} xxl={4} style={{ zIndex: "0" }}>
        <div className="BigCardFounder intro ">
          {basketColors.map(function (newColor) {
            return (
              <div
                style={{ backgroundColor: `#${newColor.hex}`, border: `solid 1px #${newColor.hex}` }}
                className="founderSmallColumn"
              />
            );
          })}
          {tableSmall}
        </div>
      </Col>

      <div className="founder " style={{ display: `${modal}` }} onClick={handleClose}>
        <CloseOutlined className="closeBig" style={{ color: `#ffffff` }} onClick={handleClose} />

        <div className="flex-center-true">
          <div className="founderWrapper">
            <div className="founderRow">
              {basketColors.map(function (newColor) {
                return (
                  <div
                    style={{ backgroundColor: `#${newColor.hex}`, border: `solid 1px #${newColor.hex}` }}
                    className="founderColumn"
                  />
                );
              })}
              {table}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FounderSmall;
