export default function Complement(hexcolor) {
  if (hexcolor !== undefined) {
    const pureHex = hexcolor.replace("#", "");
    const hexToDecimal = parseInt(pureHex.substr(0), 16);
    const complementColor = 16777215 - hexToDecimal;
    const complementHex = "#" + (complementColor + Math.pow(16, 6)).toString(16).slice(-6).toUpperCase();

    return complementHex;
  }
}
