import React, { useEffect, useState } from "react";
import { Switch, Route, Link } from "react-router-dom";
import {
  Intro,
  SpecialColors,
  MyColors,
  ColorBasket,
  TermsAndConditions,
  ColorDetail,
  Founder,
  NFWe,
  SearchApp,
  PunkColors,
  Subgraph,
} from "../views";
import Account from "./Account";
import DesktopMenu from "./DesktopMenu";
import Header from "./Header";
import MobileMenu from "./MobileMenu";
import MenuColours from "./MenuColours";
import { ShoppingCartOutlined } from "@ant-design/icons";

const blockExplorer = "https://etherscan.io/"; // for xdai: "https://blockscout.com/poa/xdai/"

const AppConnected = () => {
  const [backgroundColor, setBackgroundColor] = useState("#123ABC");
  const [route, setRoute] = useState();

  useEffect(() => {
    setRoute(window.location.pathname);
  }, [setRoute]);

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Intro backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor} />
        </Route>
        <Route exact path="/ColorDetail">
          <ColorDetail blockExplorer={blockExplorer} />
        </Route>

        <Route path="/search">
          <SearchApp setRoute={setRoute} backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor} />
        </Route>
        <Route path="/founder">
          <Founder />
        </Route>
        <Route path="/mycolors">
          <MyColors />
        </Route>
        <Route path="/nfwe">
          <NFWe />
        </Route>
        <Route path="/colorbasket" key="colorbasketroute">
          <ColorBasket />
        </Route>
        <Route path="/specialcolors" key="specialcolors">
          <SpecialColors />
        </Route>
        <Route path="/punkcolors" key="punkcolors">
          <PunkColors />
        </Route>
        <Route path="/subgraph" key="subgraph">
          <Subgraph />
        </Route>
        <Route path="/termsandconditions" key="/termsandconditions">
          <TermsAndConditions />
        </Route>

        <Route path="/:hex([0-9A-F]{6})" key="/hex">
          <ColorDetail />
        </Route>
      </Switch>

      <DesktopMenu route={route} setRoute={setRoute} />

      <Link
        to="/"
        key="/"
        onClick={() => {
          setRoute("/");
        }}
      >
        <Header />
      </Link>

      <MobileMenu route={route} setRoute={setRoute} blockExplorer={blockExplorer} />

      {/* 👨‍💼 Your account is in the top right with a wallet at connect options */}
      <div className="hiddenMobile" style={{ position: "fixed", right: 0, top: 10, padding: 10 }}>
        {/* <ShoppingCartOutlined />
        <MenuColours />  */}
        <Account blockExplorer={blockExplorer} />
      </div>
    </>
  );
};

export default AppConnected;
