import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Col } from "antd";
import { Link } from "react-router-dom";
import { ContrastHigh } from "../hooks";
import { useAppContext } from "../AppContextWrapper";

function Colour({ id, hex, contrast }) {
  const { removeColorFromBasket } = useAppContext();

  function handleDelete() {
    removeColorFromBasket(id);
  }

  return (
    <Col xs={12} sm={8} md={6} lg={6} xl={4} xxl={4}>
      <div style={{ backgroundColor: `#${hex}` }} className="cardhover">
        <h5 className="margin-bottom-zero">
          <b className="MetaHexName" style={{ color: `${ContrastHigh(hex)}` }}>
            #{hex}
          </b>
          <span className="MetaPrice" style={{ color: `${ContrastHigh(hex)}` }}>
            $5
          </span>
        </h5>

        <CloseOutlined onClick={handleDelete} className="xitemHover" style={{ color: `${ContrastHigh(hex)}` }} />
        <div className="basketCard hoverHidden ">
          <CloseOutlined onClick={handleDelete} className="xitem" />
          <Link to={`/${hex}`}>
            <div className="circleCenterer square">
              <div
                className="selectedElement"
                style={{ backgroundColor: `#${hex}`, border: `solid 2px ${contrast}` }}
              />
            </div>
          </Link>

          <h5>
            <b className="MetaHexName">#{hex}</b>
            <span className="MetaPrice">$5</span>
          </h5>
        </div>
      </div>
    </Col>
  );
}

export default Colour;
