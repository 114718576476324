import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Card, Row, Spin, Tabs } from "antd";
import { useLocation } from "react-router-dom";
import { ConsoleSqlOutlined, LoadingOutlined } from "@ant-design/icons";
import { getAddress } from "@ethersproject/address";
import { BigNumber } from "ethers";
import { useAppContext } from "../AppContextWrapper";
import { ContrastHigh, useContractReader } from "../hooks";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

export default function Founder() {
  const { setPreviousUrl, readContracts } = useAppContext();
  const [founderTokensTotal, setFounderTokensTotal] = useState([]);
  const location = useLocation();
  const totalSupply = useContractReader(readContracts, "Founder", "totalSupply");
  const { t } = useTranslation();

  useEffect(() => {
    setPreviousUrl(location);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const arr = [];

  useEffect(() => {
    if (readContracts) {
      let i;
      for (i = 0; i < totalSupply; i++) {
        arr.push(readContracts.Founder.tokenByIndex(i));
      }
      arr.map(async tokenId => {
        const svg = await readContracts.Founder.renderSVG(tokenId);
        const owner = await readContracts.Founder.ownerOf(tokenId);

        if (totalSupply > founderTokensTotal.length) {
          setFounderTokensTotal(t => [
            ...t,
            { tokenId, owner, svg: svg.replace(/([^a-z0-9='<.>:#;|{}&%/\- ]+)/gi, "") },
          ]);
        }
      });
    }
  }, [totalSupply]);

  return (
    <div key="gallery">
      <div key="colorbasket" className="container" className="rowsSubpage">
        <div className="container">
          <h2>
            <b>{t("founder_gallery")}</b>
          </h2>

          <Row gutter={[0, 8]} className="tabfontsize">
            <div className="spacerLG" />

            {founderTokensTotal
              .map(({ tokenId, owner, svg }) => (
                <div style={{ marginRight: "48px" }}>
                  {/* <a
                    href={`https://opensea.io/assets/0x555b172a3e0f0c417b295216cc7376e8b0d410b7/${tokenId}`}
                    target="_blank"
                  > */}
                  <div dangerouslySetInnerHTML={{ __html: svg }} />
                  {/* </a> */}
                  {t("owner")}:
                  <a href={`https://etherscan.io/address/${owner}`} className="Detail" target="_blank">
                    {owner.substr(0, 6) + "..." + owner.substr(-4)}
                  </a>
                  <p>
                    <br />
                  </p>
                  <div className="spacerLG" />
                </div>
              ))
              .reverse()}
          </Row>
        </div>
      </div>
    </div>
  );
}
