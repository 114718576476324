import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../AppContextWrapper";

const numbOfColor = 1000;

let color = "";

// function decimalToHex(decimal) {
//   return "#" + (decimal + Math.pow(16, 6)).toString(16).slice(-6).toUpperCase();
// }

const farben = [];

const farbe = () => {
  const letters = "0123456789ABCDEF";
  for (let i = 0; i < numbOfColor; i++) {
    color = "";
    for (let k = 0; k < 6; k++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    farben.push(color);
  }
};

farbe();

const ItemsIntro = ({ backgroundColor, setBackgroundColor }) => {
  const { addColorToBasket } = useAppContext();

  const history = useHistory();

  return (
    <div>
      {farben.map(value => (
        <div
          onClick={e => {
            console.log("11111111", backgroundColor, e.target.title);
            setBackgroundColor(e.target.title);
            history.push("/search");
            console.log("22222222", backgroundColor);
          }}
          className="box"
          value={value}
          title={value}
          style={{ backgroundColor: `#${value}` }}
          key={value}
        />
      ))}
    </div>
  );
};

export default ItemsIntro;
