import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import { FooterBasket } from ".";
import { useAppContext } from "../AppContextWrapper";
import { useTranslation } from "react-i18next";

function Footer() {
  const { basketColors } = useAppContext();
  // const [color, setColor] = useState("#123ABC");
  // const [backgroundColor, setBackgroundColor] = useState("#123ABC");
  // const [hexSearch, setHexSearch] = useState("#123ABC");
  const footer = [];
  // const integerColors = basketColors.map(({ integrer }) => integrer);
  const history = useHistory();
  const { t } = useTranslation();

  if (basketColors.length > 0) {
    let text = "";
    if (basketColors.length === 1) {
      text = `#${basketColors[0].hex}`;
    } else {
      text = `them`;
    }

    footer.push(
      <Row gutter={[0, 0]} className="footer hiddenDesktop" key="RowFooter">
        <Col span={24} className="footerColors" style={{}}>
          {basketColors
            .map(function (newColor) {
              return (
                <FooterBasket
                  hex={newColor.hex}
                  string={newColor.string}
                  contrast={newColor.contrast}
                  id={newColor.id}
                  key={newColor.hex}
                />
              );
            })
            .reverse()}
        </Col>
        <button onClick={() => history.push("/colorbasket")} className="ButtonPrimary basketButton">
          {t("go_to_basket")}
        </button>
      </Row>,
    );
  }

  return <>{footer}</>;
}

export default Footer;
