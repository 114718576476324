import React, { useEffect, useState } from "react";
import { Button, Input, Tag, notification } from "antd";

function AddColourForm({ addColorToBasket, color, backgroundColor, hexColor }) {
  const [hex, setHex] = useState();

  function handleSubmit(e) {
    e.preventDefault();
    var re = /[0-9A-Fa-f]{6}/g;
    var inputString = hex;

    if (re.test(inputString)) {
      addColorToBasket(hex.toUpperCase());
    } else {
      notification.open({
        message: "This is not a hex value",
        icon: "🙅 ",
      });
    }

    re.lastIndex = 0; // be sure to reset the index after using .text()
  }

  return (
    <div key="input">
      <Input
        value={hex}
        onChange={e => setHex(e.target.value)}
        placeholder="#Search for HEX Color such as #123ABC"
        onPressEnter={handleSubmit}
        size="large"
        key={hex}
      />
    </div>
  );
}

export default AddColourForm;
