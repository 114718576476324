import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { useAppContext } from "../AppContextWrapper";

function FooterBasket({ hex, contrast, id }) {
  const { removeColorFromBasket } = useAppContext();

  function handleDelete() {
    removeColorFromBasket(id);
  }

  return (
    <>
      <div className="basketElementWrap">
        <div className="basketElement" style={{ backgroundColor: `#${hex}`, border: `solid 2px ${contrast}` }}>
          <CloseOutlined onClick={handleDelete} className="xitem2" style={{ color: `${contrast}` }} />
        </div>
      </div>
    </>
  );
}

export default FooterBasket;
