import React, { useEffect, useState } from "react";
import { Row, Menu, Col, Dropdown } from "antd";
import { TranslationOutlined, DownOutlined } from "@ant-design/icons";
import ItemsStart from "./ItemsStart";
import { useAppContext } from "../AppContextWrapper";
import { useTranslation, Trans } from "react-i18next";
import i18next from "i18next";

const AppIntro = () => {
  const [route, setRoute] = useState();
  const { t } = useTranslation();
  const { address, connect, provider } = useAppContext();

  useEffect(() => {
    setRoute(window.location.pathname);
  }, [setRoute]);

  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "cn",
      name: "中文",
      country_code: "cn",
    },
  ];

  const menu = (
    <Menu>
      {languages.map(({ code, name, country_code }) => (
        <Menu.Item onClick={() => i18next.changeLanguage(code)}>{name}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="container mainpage" key="intro">
      <ItemsStart />

      <Row gutter={[24, 24]} className="rows hiddenMobile zindex1000 ">
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="column hiddenMobile">
          <div className="right">
            <div className="intro start noscroll">
              <h2>
                <b className="logofont">Colorverse</b>
              </h2>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="column">
          <div className="right">
            <div className="intro start">
              <h2>
                <b> {t("all_colors_tokenized")} </b>
                <br />
                {t("find_your_colors")}
                <br />
                {t("own_your_colors")}
                <div style={{ marginBottom: "15px" }} />
              </h2>

              <div style={{ marginBottom: "15px" }}>
                <h4>
                  <Trans i18nKey="the_first_meta_nft_for_generative_art">
                    The <b>first meta-NFT</b> for generative art.
                    <br />
                    <b>100% on-chain.</b>
                  </Trans>
                </h4>
              </div>
              <p>
                <b>{t("total_colors")} 16,777,216.</b>
              </p>
              <br />

              <button onClick={() => window.open("https://metamask.io/", "_blank")} className="ButtonPrimary">
                {t("install")} Metamask
              </button>
              <div className="spacerLG" />
            </div>
          </div>
        </Col>
      </Row>

      <Row gutter={[24, 24]} className="rowsMobile hiddenDesktop zindex1000 ">
        <div className="rightStart">
          <div className=" start ">
            <h2>
              <b className="logofont">Colorverse</b>
            </h2>
          </div>
          <div className="spacerSM" />
          <button
            onClick={() => window.open("https://metamask.app.link/dapp/colorverse.io/", "_blank")}
            className="ButtonPrimary"
          >
            {t("use")} Metamask App
          </button>
          <div className="spacerSM" />

          <div className="intro start">
            <h2>
              <b> {t("all_colors_tokenized")} </b>
              <br />
              {t("find_your_colors")}
              <br />
              {t("own_your_colors")}
              <div style={{ marginBottom: "15px" }} />
            </h2>

            <div style={{ marginBottom: "15px" }}>
              <h4>
                <Trans i18nKey="the_first_meta_nft_for_generative_art">
                  The <b>first meta-NFT</b> for generative art.
                  <br />
                  <b>100% on-chain.</b>
                </Trans>
              </h4>
            </div>
            <p>
              <b>{t("total_colors")} 16,777,216.</b>
            </p>
            <br />

            <div className="spacerLG" />
          </div>
        </div>
      </Row>
      {/* <Dropdown overlay={menu}>
        <a className="ant-dropdown-link language" onClick={e => e.preventDefault()}>
          <TranslationOutlined /> <DownOutlined />
        </a>
      </Dropdown> */}
    </div>
  );
};

export default AppIntro;
