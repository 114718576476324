import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { Colour, Footer, Founder } from "../components";
import { Contrast } from "../hooks";
import { useAppContext } from "../AppContextWrapper";
import { useTranslation, Trans } from "react-i18next";

export default function SpecialColors() {
  const { basketColors, setPreviousUrl, connect, readContracts, provider } = useAppContext();
  const location = useLocation();

  const numbOfColors = basketColors.length;
  const history = useHistory();
  useEffect(() => {
    setPreviousUrl(location);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const pureRed = [
    { name: "010000" },
    { name: "020000" },
    { name: "030000" },
    { name: "040000" },
    { name: "050000" },
    { name: "060000" },
    { name: "070000" },
    { name: "080000" },
    { name: "090000" },
    { name: "0a0000" },
    { name: "0b0000" },
    { name: "0c0000" },
    { name: "0d0000" },
    { name: "0e0000" },
    { name: "0f0000" },

    { name: "100000" },
    { name: "110000" },
    { name: "120000" },
    { name: "130000" },
    { name: "140000" },
    { name: "150000" },
    { name: "160000" },
    { name: "170000" },
    { name: "180000" },
    { name: "190000" },
    { name: "1a0000" },
    { name: "1b0000" },
    { name: "1c0000" },
    { name: "1d0000" },
    { name: "1e0000" },
    { name: "1f0000" },

    { name: "200000" },
    { name: "210000" },
    { name: "220000" },
    { name: "230000" },
    { name: "240000" },
    { name: "250000" },
    { name: "260000" },
    { name: "270000" },
    { name: "280000" },
    { name: "290000" },
    { name: "2a0000" },
    { name: "2b0000" },
    { name: "2c0000" },
    { name: "2d0000" },
    { name: "2e0000" },
    { name: "2f0000" },

    { name: "300000" },
    { name: "310000" },
    { name: "320000" },
    { name: "330000" },
    { name: "340000" },
    { name: "350000" },
    { name: "360000" },
    { name: "370000" },
    { name: "380000" },
    { name: "390000" },
    { name: "3a0000" },
    { name: "3b0000" },
    { name: "3c0000" },
    { name: "3d0000" },
    { name: "3e0000" },
    { name: "3f0000" },

    { name: "400000" },
    { name: "410000" },
    { name: "420000" },
    { name: "430000" },
    { name: "440000" },
    { name: "450000" },
    { name: "460000" },
    { name: "470000" },
    { name: "480000" },
    { name: "490000" },
    { name: "4a0000" },
    { name: "4b0000" },
    { name: "4c0000" },
    { name: "4d0000" },
    { name: "4e0000" },
    { name: "4f0000" },

    { name: "500000" },
    { name: "510000" },
    { name: "520000" },
    { name: "530000" },
    { name: "540000" },
    { name: "550000" },
    { name: "560000" },
    { name: "570000" },
    { name: "580000" },
    { name: "590000" },
    { name: "5a0000" },
    { name: "5b0000" },
    { name: "5c0000" },
    { name: "5d0000" },
    { name: "5e0000" },
    { name: "5f0000" },

    { name: "600000" },
    { name: "610000" },
    { name: "620000" },
    { name: "630000" },
    { name: "640000" },
    { name: "650000" },
    { name: "660000" },
    { name: "670000" },
    { name: "680000" },
    { name: "690000" },
    { name: "6a0000" },
    { name: "6b0000" },
    { name: "6c0000" },
    { name: "6d0000" },
    { name: "6e0000" },
    { name: "6f0000" },

    { name: "700000" },
    { name: "710000" },
    { name: "720000" },
    { name: "730000" },
    { name: "740000" },
    { name: "750000" },
    { name: "760000" },
    { name: "770000" },
    { name: "780000" },
    { name: "790000" },
    { name: "7a0000" },
    { name: "7b0000" },
    { name: "7c0000" },
    { name: "7d0000" },
    { name: "7e0000" },
    { name: "7f0000" },

    { name: "800000" },
    { name: "810000" },
    { name: "820000" },
    { name: "830000" },
    { name: "840000" },
    { name: "850000" },
    { name: "860000" },
    { name: "870000" },
    { name: "880000" },
    { name: "890000" },
    { name: "8a0000" },
    { name: "8b0000" },
    { name: "8c0000" },
    { name: "8d0000" },
    { name: "8e0000" },
    { name: "8f0000" },

    { name: "900000" },
    { name: "910000" },
    { name: "920000" },
    { name: "930000" },
    { name: "940000" },
    { name: "950000" },
    { name: "960000" },
    { name: "970000" },
    { name: "980000" },
    { name: "990000" },
    { name: "9a0000" },
    { name: "9b0000" },
    { name: "9c0000" },
    { name: "9d0000" },
    { name: "9e0000" },
    { name: "9f0000" },

    { name: "a00000" },
    { name: "a10000" },
    { name: "a20000" },
    { name: "a30000" },
    { name: "a40000" },
    { name: "a50000" },
    { name: "a60000" },
    { name: "a70000" },
    { name: "a80000" },
    { name: "a90000" },
    { name: "aa0000" },
    { name: "ab0000" },
    { name: "ac0000" },
    { name: "ad0000" },
    { name: "ae0000" },
    { name: "af0000" },

    { name: "b00000" },
    { name: "b10000" },
    { name: "b20000" },
    { name: "b30000" },
    { name: "b40000" },
    { name: "b50000" },
    { name: "b60000" },
    { name: "b70000" },
    { name: "b80000" },
    { name: "b90000" },
    { name: "ba0000" },
    { name: "bb0000" },
    { name: "bc0000" },
    { name: "bd0000" },
    { name: "be0000" },
    { name: "bf0000" },

    { name: "c00000" },
    { name: "c10000" },
    { name: "c20000" },
    { name: "c30000" },
    { name: "c40000" },
    { name: "c50000" },
    { name: "c60000" },
    { name: "c70000" },
    { name: "c80000" },
    { name: "c90000" },
    { name: "ca0000" },
    { name: "cb0000" },
    { name: "cc0000" },
    { name: "cd0000" },
    { name: "ce0000" },
    { name: "cf0000" },

    { name: "d00000" },
    { name: "d10000" },
    { name: "d20000" },
    { name: "d30000" },
    { name: "d40000" },
    { name: "d50000" },
    { name: "d60000" },
    { name: "d70000" },
    { name: "d80000" },
    { name: "d90000" },
    { name: "da0000" },
    { name: "db0000" },
    { name: "dc0000" },
    { name: "dd0000" },
    { name: "de0000" },
    { name: "df0000" },

    { name: "e00000" },
    { name: "e10000" },
    { name: "e20000" },
    { name: "e30000" },
    { name: "e40000" },
    { name: "e50000" },
    { name: "e60000" },
    { name: "e70000" },
    { name: "e80000" },
    { name: "e90000" },
    { name: "ea0000" },
    { name: "eb0000" },
    { name: "ec0000" },
    { name: "ed0000" },
    { name: "ee0000" },
    { name: "ef0000" },

    { name: "f00000" },
    { name: "f10000" },
    { name: "f20000" },
    { name: "f30000" },
    { name: "f40000" },
    { name: "f50000" },
    { name: "f60000" },
    { name: "f70000" },
    { name: "f80000" },
    { name: "f90000" },
    { name: "fa0000" },
    { name: "fb0000" },
    { name: "fc0000" },
    { name: "fd0000" },
    { name: "fe0000" },
    { name: "ff0000" },
  ];

  const [list, updateList] = useState(pureRed);
  const { addColorToBasket } = useAppContext();
  const { t } = useTranslation();
  console.log(list);

  const handleRemoveItem = colorToDelete => {
    const name = colorToDelete;
    updateList(list.filter(item => item.name !== name));
  };

  const hexToDecimal = hex => parseInt(hex.substr(0), 16);

  const getBalance = async color => {
    if (readContracts) {
      const exist = await readContracts.Colorverse.exists(hexToDecimal(color));
      if (!exist) {
        handleRemoveItem(color);
      }
    }
  };

  useEffect(() => {
    for (const color of pureRed) {
      getBalance(color.name);
    }
  }, [readContracts]);

  const modalButtonsTwo = [];
  if (provider) {
    if (numbOfColors > 0) {
      modalButtonsTwo.push(
        <Col span={24} className="hiddenMobile fixedBasket zindex1000">
          <div className="CheckoutFooterSelection">
            <div className="FounderSentence">
              <h3>
                <b>{t("got_all_the_colors_you_like")}</b>
              </h3>
              <br />
              <p>
                {t("anyone_minting_16_colors_in_a_transaction_can_claim_a_founder")} <Founder />.
              </p>
            </div>
            <button onClick={() => history.push("/colorbasket")} className="ButtonPrimary ">
              {t("go_to_basket")}
            </button>
          </div>
        </Col>,
      );
    }
  } else {
    modalButtonsTwo.push(
      <Col xs={24} sm={18} md={18} lg={12} xl={12} xxl={12} key="modal4 hiddenMobile zindex1000">
        <div className="BigCard intro" style={{ backgroundColor: "#222222" }}>
          <span className="callToAction">Connect your wallet to buy colors.</span>

          <p className="SelectCTA">
            <button onClick={connect} className="ButtonPrimary">
              {t("connect_metamask")}
            </button>
          </p>
        </div>
      </Col>,
    );
  }

  return (
    <div key="specialcolors">
      <div key="specialcolors1" className="container" className="rowsSubpage">
        <h1>
          <b>{t("pure_shades_of_red")}</b>
        </h1>
        <Row gutter={[8, 8]} className="hiddenMobile zindexbottom">
          <div className="basketColors">
            {basketColors
              .map(function (newColor, key) {
                return (
                  <Colour
                    hex={newColor.hex}
                    string={newColor.string}
                    contrast={newColor.contrast}
                    id={newColor.id}
                    key={newColor.id}
                  />
                );
              })
              .reverse()}
            <div className="hoverIntro" style={{ display: "none" }} />
            <div className="hoverdots" style={{ display: "none" }} />
          </div>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <div>
              {list
                .map(item => {
                  return (
                    <div
                      onClick={e => addColorToBasket(e.target.title)}
                      className="boxSpecial"
                      value={item.name}
                      title={item.name}
                      style={{ backgroundColor: `#${item.name}`, color: `${Contrast(item.name)}` }}
                      key={item.name}
                    >
                      #{item.name}
                    </div>
                  );
                })
                .reverse()}
            </div>
          </Col>
        </Row>
        <div className="spacingSM" />
      </div>
      <Footer basketColors={basketColors} className="zindex1000" />
      {modalButtonsTwo}

      <div className="spacerLG" />
      <div className="spacerLG" />
      <div className="spacerLG" />
      <div className="spacerLG" />
    </div>
  );
}
