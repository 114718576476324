import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Col } from "antd";
import { Link } from "react-router-dom";
import { useAppContext } from "../AppContextWrapper";

function ColourBasketItems({ id, hex, contrast }) {
  const { removeColorFromBasket } = useAppContext();

  function handleDelete() {
    removeColorFromBasket(id);
  }

  return (
    <Col span={24}>
      <div style={{ backgroundColor: "#131313" }}>
        <div className="circleCentererBasket">
          <div>
            <Link to={`/${hex}`}>
              <div
                className="selectedElementBasket"
                style={{ backgroundColor: `#${hex}`, border: `solid 2px ${contrast}` }}
              />
            </Link>

            <h4 className="basketHex">
              <b>#{hex}</b>
            </h4>
            <h4 className="basketHex">$5</h4>
          </div>
          <div className="basketDetails">
            <CloseOutlined onClick={handleDelete} className="xitemBasket" />
          </div>
        </div>
      </div>
    </Col>
  );
}

export default ColourBasketItems;
