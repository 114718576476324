import React, { useEffect, useState } from "react";
import { Input, Row, Col, notification, Checkbox } from "antd";
import { ColourBasketItems } from "../components";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import { CustomPicker } from "react-color";
import { useHistory, useLocation } from "react-router-dom";
import { useAppContext } from "../AppContextWrapper";
import { useTranslation, Trans } from "react-i18next";

export default function Colorbasket() {
  const { basketColors, mintColors, setPreviousUrl, connect, address } = useAppContext();
  const location = useLocation();
  const { t } = useTranslation();

  const integerColors = basketColors.map(({ integrer }) => integrer);
  const history = useHistory();
  const numbOfColors = basketColors.length;

  const modalButtonsTwo = [];

  const [agree, setAgree] = useState(false);

  useEffect(() => {
    setPreviousUrl(location);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const checkboxHandler = () => {
    setAgree(!agree);
  };

  const x = numbOfColors;
  const preis = numbOfColors * 5;

  if (x === 1) {
    modalButtonsTwo.push(
      <>
        <Col span={24} className="hiddenMobile">
          <div className="BigCard CheckoutFooter">
            <div className="FooterSide">
              <h2>
                <b> #{basketColors[0].hex}</b> {t("for")} <b>${preis}.</b>
              </h2>
              <br />
              <h5>{t("gas_fee")}</h5>
            </div>
            <div className="FooterSide rightFooter">
              {address !== "" ? (
                <>
                  <div className="termsWrapper">
                    <input type="checkbox" onChange={checkboxHandler} className="SelectTerms" />
                    <div className="SelectSentence">
                      <p>
                        {t("to_buy_colors_you_must_agree_to_the")}
                        <a href="#/termsandconditions"> Terms and Conditions</a>
                      </p>
                    </div>
                  </div>

                  <button disabled={!agree} onClick={() => mintColors(integerColors)} className="ButtonPrimary">
                    {t("buy")} #{basketColors[0].hex}
                  </button>
                </>
              ) : (
                <button onClick={() => connect()} className="ButtonPrimary">
                  {t("connect_metamask")}
                </button>
              )}
            </div>
          </div>
        </Col>
        {/* ************ MOBILE ***************** */}

        {address !== "" ? (
          <>
            <div className="footerMobileCheckout hiddenDesktop zindextop">
              <div className="flexbetween">
                <Checkbox onChange={checkboxHandler} className="SelectTerms" style={{ marginRight: "16px" }} />

                <p>
                  {t("to_buy_colors_you_must_agree_to_the")}&nbsp;
                  <a href="#/termsandconditions">Terms and Conditions</a>
                </p>
              </div>
            </div>
            <Row
              gutter={[8, 8]}
              className="footerCheckout hiddenDesktop left-zero flex-center zindextop"
              key="RowFooter"
            >
              <div className="basketItems">
                <h3>
                  <b> #{basketColors[0].hex}</b> {t("for")} <b>${preis}.</b>
                </h3>
                <br />
                <h5>{t("gas_fee")}</h5>
              </div>
              <button
                disabled={!agree}
                onClick={() => mintColors(integerColors)}
                className="ButtonPrimary basketButton"
              >
                {t("buy")}
              </button>
            </Row>
          </>
        ) : (
          <Row gutter={[8, 8]} className="footerCheckout hiddenDesktop left-zero flex-center zindextop" key="RowFooter">
            <button onClick={() => connect()} className="ButtonPrimary basketButton">
              {t("connect_metamask")}
            </button>
          </Row>
        )}
      </>,
    );
  } else if (x === 2) {
    modalButtonsTwo.push(
      <>
        <Col span={24} className="hiddenMobile">
          <div className="BigCard CheckoutFooter">
            <div className="FooterSide">
              <h2>
                <b>
                  {numbOfColors} {t("colors")}
                </b>
                {t("for")} <b>${preis}.</b>
              </h2>
              <br />
              <h5>{t("gas_fee")}</h5>
            </div>
            <div className="FooterSide rightFooter">
              {address !== "" ? (
                <>
                  <div className="termsWrapper">
                    <input type="checkbox" onChange={checkboxHandler} className="SelectTerms" />
                    <div className="SelectSentence">
                      <p>
                        {t("to_buy_colors_you_must_agree_to_the")}
                        <a href="#/termsandconditions"> Terms and Conditions</a>
                      </p>
                    </div>
                  </div>

                  <button disabled={!agree} onClick={() => mintColors(integerColors)} className="ButtonPrimary ">
                    {t("buy_colors")}
                  </button>
                </>
              ) : (
                <button onClick={() => connect()} className="ButtonPrimary ">
                  {t("connect_metamask")}
                </button>
              )}
            </div>
          </div>
        </Col>

        {/* ************ MOBILE ***************** */}

        {address !== "" ? (
          <>
            <div className="footerMobileCheckout hiddenDesktop zindextop">
              <div className="flexbetween">
                <Checkbox onChange={checkboxHandler} className="SelectTerms" style={{ marginRight: "16px" }} />

                <p>
                  {t("to_buy_colors_you_must_agree_to_the")}&nbsp;
                  <a href="#/termsandconditions">Terms and Conditions</a>
                </p>
              </div>
            </div>
            <Row
              gutter={[8, 8]}
              className="footerCheckout hiddenDesktop left-zero flex-center zindextop"
              key="RowFooter"
            >
              <div className="basketItems">
                <h3>
                  <b>
                    {" "}
                    {numbOfColors} {t("colors")}
                  </b>{" "}
                  {t("for")} <b>${preis}.</b>
                </h3>
                <br />
                <h5>{t("gas_fee")}</h5>
              </div>
              <button
                disabled={!agree}
                onClick={() => mintColors(integerColors)}
                className="ButtonPrimary basketButton"
              >
                {t("buy")}
              </button>
            </Row>
          </>
        ) : (
          <Row gutter={[8, 8]} className="footerCheckout hiddenDesktop left-zero flex-center zindextop" key="RowFooter">
            <button onClick={() => connect()} className="ButtonPrimary basketButton">
              {t("connect_metamask")}
            </button>
          </Row>
        )}
      </>,
    );
  } else if (x > 2) {
    modalButtonsTwo.push(
      <div key="modal3">
        <Row gutter={[0, 0]} className="footerBasket  left-zero flex-center zindextop">
          <Col span={24} className="hiddenMobile">
            <div className=" CheckoutFooter">
              <div className="FooterSide">
                <h3>
                  <b>
                    {" "}
                    {numbOfColors} {t("colors")}
                  </b>{" "}
                  {t("for")} <b>${preis}.</b>
                </h3>
                <br />
                <h5>{t("gas_fee")}</h5>
              </div>
              <div className="FooterSide rightFooter3Colors">
                {address !== "" ? (
                  <>
                    <div className="termsWrapper3Colors">
                      <Checkbox onChange={checkboxHandler} className="SelectTerms" />
                      <div className="SelectSentence3Colors">
                        <p>
                          {t("to_buy_colors_you_must_agree_to_the")}
                          <a href="#/termsandconditions"> Terms and Conditions</a>
                        </p>
                      </div>
                    </div>

                    <button
                      disabled={!agree}
                      onClick={() => mintColors(integerColors)}
                      className="ButtonPrimary Button-16px"
                    >
                      {t("buy_colors")}
                    </button>
                  </>
                ) : (
                  <button onClick={() => connect()} className="ButtonPrimary">
                    {t("connect_metamask")}
                  </button>
                )}
              </div>
            </div>
          </Col>
        </Row>
        {/* ************ MOBILE ***************** */}

        {address !== "" ? (
          <>
            <div className="footerMobileCheckout hiddenDesktop zindextop">
              <div className="flexbetween">
                <Checkbox onChange={checkboxHandler} className="SelectTerms" style={{ marginRight: "16px" }} />

                <p>
                  {t("to_buy_colors_you_must_agree_to_the")}&nbsp;
                  <a href="#/termsandconditions">Terms and Conditions</a>
                </p>
              </div>
            </div>
            <Row
              gutter={[8, 8]}
              className="footerCheckout hiddenDesktop left-zero flex-center zindextop"
              key="RowFooter"
            >
              <div className="basketItems">
                <h3>
                  <b>
                    {" "}
                    {numbOfColors} {t("colors")}
                  </b>{" "}
                  {t("for")} <b>${preis}.</b>
                </h3>
                <br />
                <h5>{t("gas_fee")}</h5>
              </div>
              <button
                disabled={!agree}
                onClick={() => mintColors(integerColors)}
                className="ButtonPrimary basketButton"
              >
                {t("buy")}
              </button>
            </Row>
          </>
        ) : (
          <Row gutter={[8, 8]} className="footerCheckout hiddenDesktop left-zero flex-center zindextop" key="RowFooter">
            <button onClick={() => connect()} className="ButtonPrimary basketButton">
              {t("connect_metamask")}
            </button>
          </Row>
        )}
      </div>,
    );
  }

  return (
    <div key="colorbasketX">
      <div key="colorbasket" className="container" className="rowsSubpage">
        <div className="container">
          <h2>
            <b>Your color basket.</b>
          </h2>
        </div>
        <div className="spacerLG" />
        <Row gutter={[8, 8]}>
          <div className="basketColors">
            {basketColors
              .map(function (newColor) {
                return (
                  <ColourBasketItems
                    hex={newColor.hex}
                    string={newColor.string}
                    contrast={newColor.contrast}
                    id={newColor.id}
                    key={newColor.id}
                  />
                );
              })
              .reverse()}

            {modalButtonsTwo}
          </div>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
            <button
              onClick={() => history.push("/search")}
              style={{ marginRight: "8px", marginTop: "20px" }}
              className="ButtonSecondary zindexbottom"
            >
              {t("add_colors")}
            </button>
          </Col>
        </Row>
        <div className="spacerLG" />
        <div className="spacerLG" />
        <div className="spacerLG" />
        <div className="spacerLG" />
      </div>
    </div>
  );
}
