import React, { useEffect, useState } from "react";
import { useAppContext } from "../AppContextWrapper";
import { ContrastHigh } from "../hooks";

function MenuColours() {
  const { basketColors } = useAppContext();

  const smallColor = [];

  if (basketColors.length === 1) {
    smallColor.push(
      <div key="MenuColors">
        <div
          className="menuColors"
          style={{
            backgroundColor: `#${basketColors[0].hex}`,
            border: `solid 2px #000000`,
            fontSize: `10px`,
            color: `${ContrastHigh(basketColors[0].hex)}`,
          }}
        >
          <div className="number">{basketColors.length}</div>
        </div>
      </div>,
    );
  } else if (basketColors.length === 2) {
    smallColor.push(
      <div
        key="MenuColors2"
        className="smallMenuColorBasket"
        // style={{ marginRight: "14px", marginLeft: "8px", paddingTop: "4px" }}
      >
        <div
          className="menuColors"
          style={{
            backgroundColor: `#${basketColors[1].hex}`,
            border: `solid 2px #000000`,
            fontSize: `10px`,
            color: `${ContrastHigh(basketColors[1].hex)}`,
          }}
        ></div>
        <div
          className="menuColors"
          style={{
            backgroundColor: `#${basketColors[0].hex}`,
            border: `solid 2px #000000`,
            fontSize: `10px`,
            color: `${ContrastHigh(basketColors[0].hex)}`,
          }}
        >
          <div className="number">{basketColors.length}</div>
        </div>
      </div>,
    );
  } else if (basketColors.length > 2) {
    smallColor.push(
      <div key="MenuColors3" className="smallMenuColorBasket">
        <div
          className="menuColors"
          style={{
            backgroundColor: `#${basketColors[2].hex}`,
            border: `solid 2px #000000`,
            fontSize: `10px`,
            color: `${ContrastHigh(basketColors[2].hex)}`,
          }}
        ></div>
        <div
          className="menuColors"
          style={{
            backgroundColor: `#${basketColors[1].hex}`,
            border: `solid 2px #000000`,
            fontSize: `10px`,
            color: `${ContrastHigh(basketColors[1].hex)}`,
          }}
        ></div>
        <div
          className="menuColors"
          style={{
            backgroundColor: `#${basketColors[0].hex}`,
            border: `solid 2px #000000`,
            fontSize: `12px`,
            color: `${ContrastHigh(basketColors[0].hex)}`,
          }}
        >
          <div className="number">{basketColors.length}</div>
        </div>
      </div>,
    );
  }

  return (
    <>
      <div className="smallMenuColorBasket2">
        <div>{smallColor}</div>
      </div>
    </>
  );
}

export default MenuColours;
