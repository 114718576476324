import React from "react";
import { BranchesOutlined, CloseOutlined } from "@ant-design/icons";
import { Col } from "antd";
import { BrowserRouter, Link } from "react-router-dom";
import { useAppContext } from "../AppContextWrapper";

function ColourBasketItems({ id, hex, contrast, holderaddress }) {
  const { removeColorFromBasket } = useAppContext();

  function handleDelete() {
    removeColorFromBasket(id);
  }

  return (
    <Col span={24}>
      {/* <div style={{ backgroundColor: "#131313" }}> */}
      <div className="landingPageCircles">
        <div>
          <Link to={`/${hex}`}>
            <div
              className="selectedElementBasketLandingPage"
              style={{ backgroundColor: `#${hex}`, border: `solid 2px ${contrast}` }}
            />

            <p className="basketHex">
              #{hex}
              <br />
              {holderaddress.substr(0, 6) + "..." + holderaddress.substr(-4)}
            </p>
          </Link>
        </div>
      </div>
      {/* </div> */}
    </Col>
  );
}

export default ColourBasketItems;
