import React, { useEffect, useState } from "react";

function Fireworks() {
  const fireworks = [];

  fireworks.push(
    <div>
      <div class="firework" id="firework1">
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
      </div>
      <div class="firework" id="firework2">
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
      </div>
      <div class="firework" id="firework3">
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
        <div class="explosion"></div>
      </div>
    </div>,
  );

  return <>{fireworks}</>;
}

export default Fireworks;
