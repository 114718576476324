import React from "react";
import { useAppContext } from "../AppContextWrapper";

const numbOfColor = 1000;

let color = "";

// function decimalToHex(decimal) {
//   return "#" + (decimal + Math.pow(16, 6)).toString(16).slice(-6).toUpperCase();
// }

const farben = [];

const farbe = () => {
  const letters = "0123456789ABCDEF";
  for (let i = 0; i < numbOfColor; i++) {
    color = "";
    for (let k = 0; k < 6; k++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    farben.push(color);
  }
};

farbe();

const Items = () => {
  const { addColorToBasket } = useAppContext();

  return (
    <>
      {farben.map((value, key) => (
        <div
          onClick={e => addColorToBasket(e.target.title)}
          className="box"
          value={value}
          title={value}
          style={{ backgroundColor: `#${value}` }}
          key={key}
        />
      ))}
    </>
  );
};

export default Items;
