import React, { useEffect, useState } from "react";
import { useAppContext } from "../AppContextWrapper";
import { ContrastHigh } from "../hooks";
import { useTranslation } from "react-i18next";

function FooterMenuColours() {
  const { basketColors } = useAppContext();
  const { t } = useTranslation();

  const smallColor = [];

  if (basketColors.length === 1) {
    smallColor.push(
      <div key="MenuColors">
        <div
          className="two"
          style={{
            backgroundColor: `#${basketColors[0].hex}`,
            border: `solid 2px #000000`,
            fontSize: `10px`,
            color: `${ContrastHigh(basketColors[0].hex)}`,
          }}
        >
          <div className="number">{basketColors.length}</div>
        </div>
      </div>,
    );
  } else if (basketColors.length === 2) {
    smallColor.push(
      <div
        key="MenuColors2"
        className="smallMenuColorBasketRight"
        // style={{ marginRight: "14px", marginLeft: "8px", paddingTop: "4px" }}
      >
        <div
          className="two"
          style={{
            backgroundColor: `#${basketColors[1].hex}`,
            border: `solid 2px #000000`,
            fontSize: `10px`,
            color: `${ContrastHigh(basketColors[1].hex)}`,
          }}
        ></div>
        <div
          className="two"
          style={{
            backgroundColor: `#${basketColors[0].hex}`,
            border: `solid 2px #000000`,
            fontSize: `10px`,
            color: `${ContrastHigh(basketColors[0].hex)}`,
          }}
        >
          <div className="number">{basketColors.length}</div>
        </div>
      </div>,
    );
  } else if (basketColors.length > 2) {
    smallColor.push(
      <div
        key="MenuColors3"
        className="smallMenuColorBasketRight"
        // style={{ marginRight: "14px", marginLeft: "8px", paddingTop: "4px" }}
      >
        <div
          className="two"
          style={{
            backgroundColor: `#${basketColors[2].hex}`,
            border: `solid 2px #000000`,
            fontSize: `10px`,
            color: `${ContrastHigh(basketColors[2].hex)}`,
          }}
        ></div>
        <div
          className="two"
          style={{
            backgroundColor: `#${basketColors[1].hex}`,
            border: `solid 2px #000000`,
            fontSize: `10px`,
            color: `${ContrastHigh(basketColors[1].hex)}`,
          }}
        ></div>
        <div
          className="two"
          style={{
            backgroundColor: `#${basketColors[0].hex}`,
            border: `solid 2px #000000`,
            fontSize: `12px`,
            color: `${ContrastHigh(basketColors[0].hex)}`,
          }}
        >
          <div className="number">{basketColors.length}</div>
        </div>
        <p
          style={{
            fontSize: `20px`,
            marginLeft: "20px",
            marginTop: "10px",
            marginRight: "250px",
            lineHeight: "1",
          }}
        >
          <b>{t("careful_minting_too_many_rare_colors_at_once_can_lead_to_transaction_errors")}</b>
        </p>
      </div>,
    );
  }

  return (
    <>
      <div className="">{smallColor}</div>
    </>
  );
}

export default FooterMenuColours;
