import React, { useState, useEffect } from "react";
import { Input, Row, Col, notification, Tabs } from "antd";
import { Colour, Items, Footer, Founder, FounderSmall } from "../components";
import { Contrast, ContrastHigh } from "../hooks";
import { parseEther, formatEther } from "@ethersproject/units";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import { CustomPicker } from "react-color";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { useAppContext } from "../AppContextWrapper";

const { Hue, Saturation, EditableInput } = require("react-color/lib/components/common");

export default function Search({ backgroundColor, setBackgroundColor }) {
  const { basketColors, addColorToBasket, setPreviousUrl, connect, provider } = useAppContext();
  const [color, setColor] = useState("#123ABC");
  const [hexSearch, setHexSearch] = useState("#123ABC");
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    setPreviousUrl(location);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const numbOfColors = basketColors.length;

  const { TabPane } = Tabs;

  const modalButtonsTwo = [];
  const founderTile = [];

  if (provider) {
    if (numbOfColors > 2) {
      founderTile.push(
        <>
          <FounderSmall />
        </>,
      );
    }
  }

  if (provider) {
    if (numbOfColors === 1) {
      modalButtonsTwo.push(
        <Col xs={24} sm={18} md={18} lg={12} xl={12} xxl={12}>
          <div className="BigCard intro">
            <div key="callToAction">
              <span className="callToAction">
                #{basketColors[0].hex}. {t("thats_a_nice_color_get_it")}
              </span>

              <p className="SelectCTA">
                <button onClick={() => history.push("/colorbasket")} className="ButtonPrimary ">
                  {t("go_to_basket")}
                </button>
              </p>
            </div>
          </div>{" "}
        </Col>,
      );
    } else if (numbOfColors === 1 || numbOfColors === 2) {
      modalButtonsTwo.push(
        <Col xs={24} sm={18} md={18} lg={12} xl={12} xxl={12}>
          <div className="BigCard intro">
            <div key="callToAction1">
              <span className="callToAction">{t("got_all_the_colors_you_like")} </span>

              <p className="SelectCTA">
                <button onClick={() => history.push("/colorbasket")} className="ButtonPrimary ">
                  {t("go_to_basket")}
                </button>
              </p>
            </div>
          </div>{" "}
        </Col>,
      );
    } else if (numbOfColors > 2) {
      modalButtonsTwo.push(
        <Col span={24} className="hiddenMobile fixedBasket">
          <div className="CheckoutFooterSelection">
            <div className="FounderSentence">
              <h3>
                <b> {t("got_all_the_colors_you_like")}</b>
              </h3>
              <br />
              <p>
                {t("anyone_minting_16_colors_in_a_transaction_can_claim_a_founder_nft")} <Founder />.
              </p>
            </div>
            <button onClick={() => history.push("/colorbasket")} className="ButtonPrimary ">
              {t("go_to_basket")}
            </button>
          </div>
        </Col>,
      );
    } else {
      modalButtonsTwo.push(
        <Col xs={24} sm={18} md={18} lg={12} xl={12} xxl={12}>
          <div className="BigCard intro">
            <div key="callToAction3">
              <span className="callToAction">{t("find_your_colors_add_your_colors_to_the_basket")}</span>
            </div>
          </div>
        </Col>,
      );
    }
  } else {
    modalButtonsTwo.push(
      <Col xs={24} sm={18} md={18} lg={12} xl={12} xxl={12} key="modal4 hiddenMobile">
        <div className="BigCard intro" style={{ backgroundColor: "#222222" }}>
          <span className="callToAction">Connect your wallet to buy colors.</span>

          <p className="SelectCTA">
            <button onClick={connect} className="ButtonPrimary">
              {t("connect_metamask")}
            </button>
          </p>
        </div>
      </Col>,
    );
  }

  const hex = backgroundColor.replace("#", "").toUpperCase();

  return (
    <>
      <div key="search" className="container" className="rowsSubpage">
        <Tabs defaultActiveKey="1" left className="hiddenMobile">
          <TabPane tab={t("search")} key="1">
            <Row gutter={[8, 8]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="hiddenMobile">
                <div className="BigCard">
                  <ColorPickerWrapped color={color} setColor={setColor} setBackgroundColor={setBackgroundColor} />
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="hiddenMobile">
                <div className="BigCard intro">
                  <AddColourForm
                    addColorToBasket={addColorToBasket}
                    backgroundColor={backgroundColor}
                    hexColor={hex}
                    setHexSearch={setHexSearch}
                    setBackgroundColor={setBackgroundColor}
                  />
                  <div
                    className="detailDot"
                    style={{
                      backgroundColor: `#${hex}`,
                      border: `solid 2px ${Contrast(hex)}`,
                    }}
                  />

                  <span className="SelectHexName">
                    <h2>
                      <b>#{hex}</b>
                    </h2>
                  </span>

                  <p className="SelectCTA">
                    <button
                      onClick={() => history.push(`/${hex}`)}
                      className="ButtonSecondary"
                      style={{ marginRight: "8px" }}
                    >
                      {t("color_details")}
                    </button>
                    <button onClick={() => addColorToBasket(hex)} className="ButtonPrimary">
                      {t("add_color")}
                    </button>
                  </p>
                  <div className="CardBG" />
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={t("random_colors")} key="2">
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <div className="itemsField">
                  <Items />
                </div>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
        {/* ******************* MOBILE ******************** */}
        <Tabs defaultActiveKey="1" left className="hiddenDesktop">
          <TabPane tab={t("pick")} key="1" className="fixed">
            <div className="BigCardSearch fullWidth fixed">
              <div className="MobileHexNameWrap ">
                <h1 className="MobileHexName ">
                  <b>#{hex}</b>
                </h1>

                <button
                  className="detailDot"
                  style={{
                    backgroundColor: `#${hex}`,
                    border: `solid 2px ${Contrast(hex)}`,
                    color: `${ContrastHigh(hex)}`,
                  }}
                  onClick={() => addColorToBasket(hex)}
                  type="button"
                >
                  <h3
                    style={{
                      color: `${ContrastHigh(hex)}`,
                      marginBottom: "0",
                    }}
                  >
                    {t("add")}
                  </h3>
                </button>
              </div>

              <ColorPickerWrapped color={color} setColor={setColor} setBackgroundColor={setBackgroundColor} />
            </div>
          </TabPane>
          <TabPane tab={t("search")} key="2">
            <div className="BigCardSearch fullWidth fixed">
              <div className="MobileHexNameWrap ">
                <h1 className="MobileHexName ">
                  <b>#{hex}</b>
                </h1>

                <button
                  className="detailDot"
                  style={{
                    backgroundColor: `#${hex}`,
                    border: `solid 2px ${Contrast(hex)}`,
                    color: `${ContrastHigh(hex)}`,
                  }}
                  onClick={() => addColorToBasket(hex)}
                >
                  <h3
                    style={{
                      color: `${ContrastHigh(hex)}`,
                      marginBottom: "0",
                    }}
                  >
                    {t("add")}
                  </h3>
                </button>
              </div>
              <div className="spacerSM" />
              <AddColourForm
                addColorToBasket={addColorToBasket}
                backgroundColor={backgroundColor}
                hexColor={hex}
                setHexSearch={setHexSearch}
                setBackgroundColor={setBackgroundColor}
              />
            </div>
          </TabPane>
          <TabPane tab="Random" key="3">
            <div className="BigCardNoBG">
              <Items />
            </div>
          </TabPane>
        </Tabs>

        <div className="spacingSM" />
        <Row gutter={[8, 8]} className="hiddenMobile zindexbottom">
          <div className="basketColors">
            {basketColors
              .map((newColor, key) => (
                <Colour
                  hex={newColor.hex}
                  string={newColor.string}
                  contrast={newColor.contrast}
                  id={newColor.id}
                  key={key}
                />
              ))
              .reverse()}
            {founderTile}
            {modalButtonsTwo}
          </div>
        </Row>
      </div>

      <Footer basketColors={basketColors} className="zindex1000" />

      <div className="spacerLG" />
      <div className="spacerLG" />
      <div className="spacerLG" />
      <div className="spacerLG" />
    </>
  );
}

function Picker() {
  return (
    <div
      className="Picker"
      style={{
        borderRadius: 20,
        background: "rgba(255,255,255,0.2)",
        border: "1px solid white",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
        boxSizing: "border-box",
      }}
    />
  );
}

function Picker2() {
  return (
    <div
      style={{
        width: 12,
        height: 72,
        background: "#ffffff",
        border: "2px solid white",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
        boxSizing: "border-box",
        margin: "-4px 0",
      }}
    />
  );
}

const ColorPicker = props => {
  const { setColor, setBackgroundColor, hex } = props;

  const handleChange = data => {
    setColor(data);
    setBackgroundColor(hex);
  };

  return (
    <>
      <div className="colorPicker">
        <div className="colorPickerField">
          <Saturation {...props} onChange={handleChange} pointer={Picker} />
        </div>
      </div>
      <div className="colorPicker">
        <div className="colorPickerHue">
          <Hue {...props} onChange={handleChange} pointer={Picker2} />
        </div>
      </div>
    </>
  );
};

const ColorPickerWrapped = CustomPicker(ColorPicker);

function AddColourForm({ addColorToBasket, color, backgroundColor, hexColor, setHexSearch, setBackgroundColor }) {
  const [hex, setHex] = useState();
  const { t } = useTranslation();

  function handleSubmit(e) {
    e.preventDefault();
    const re = /[0-9A-Fa-f]{6}/g;
    let inputString = hex;
    inputString = inputString.replace("#", "");
    inputString = inputString.slice(0, 6);
    inputString = "#" + inputString;

    if (re.test(inputString)) {
      setBackgroundColor(inputString.toUpperCase());
      setHex("");
    } else {
      notification.open({
        message: t("this_is_not_a_hex_value"),
      });
    }

    re.lastIndex = 0; // be sure to reset the index after using .text()
  }

  return (
    <>
      <Input
        value={hex}
        onChange={e => setHex(e.target.value)}
        placeholder={t("search_hex_colors")}
        onPressEnter={handleSubmit}
        size="large"
        suffix={t("enter")}
      />
    </>
  );
}
