import React, { useCallback, useEffect, useState, useContext } from "react";
import { Row, Col } from "antd";
import "antd/dist/antd.css";

export default function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div key="style1">
      <div key="style" className="container" className="rowsSubpage">
        <h2>
          <b>Terms and Conditions.</b>
        </h2>
        <div className="spacerLG" />
        <p>
          <h3>
            <b>Colorverse</b>
          </h3>
          <div className="spacerSM" />
          The Colorverse project (hereafter Colorverse) a decentralized software system on the Ethereum network. This
          website (hereafter coloverse.io) is only an interface built to help users interact with the Ethereum smart
          contract of the Colorverse (hereafter the Colorverse Smart Contract).
          <div className="spacerLG" />
          <h3>
            <b>Ownership, Transactions and Security</b>
          </h3>
          <div className="spacerSM" />
          You are purchasing a unique token with a unique token identifier or Token ID. When you purchase a color on
          Colorverse (hereafter a Colorverse-Color) you own the Non-Fungible Token (hereafter NFT) on the Ethereum
          blockchain corresponding to that particular (24-bit hexadecimal) color within the Colorverse universe, namely
          the NFT of the purchased Colorverse-Color. However, you do not own the purchased color or any rights of, or
          associated with it outside the Colorverse universe, namely outside the Colorverse Smart Contract context.
          Ownership of the NFT is mediated entirely by the Colorverse Smart Contract and the Ethereum Network: at no
          point may Colorverse seize, freeze, or otherwise modify the ownership of any Colorverse-Color tokens. In
          addition, any NFT holder will be eligible to name or rename their owned Colorverse tokens on the Ethereum
          blockchain. Colorverse strictly prohibits the use of offensive language in the naming process. Any insulting
          names will be removed from the interface. Since the smart contracts are open to anyone, we cannot hinder
          naming or a name change of a Colorverse token on the Ethereum blockchain. Users are entirely responsible for
          the safety and management of their own private keys and Ethereum wallets, and for verifying all transactions
          generated by the website colorvers.io before approving those transactions. Use of coloverse.io is at the
          user’s own risk and Colorverse is not liable for any incorrect or failed transactions, nor for the gas costs
          incurred. Furthermore, as the Colorverse smart contract runs on the Ethereum network, Colorverse has no power
          to undo, reverse, or restore any transactions. By using coloverse.io, and any related tools it may provide,
          you agree to hold Colorverse harmless for any and all problems, errors, and failures no matter the cause or
          source of those errors and accept sole responsibility for all transactions.
          <div className="spacerLG" />
          <h3>
            <b>Tax</b>
          </h3>
          <div className="spacerSM" />
          All paid prices exclude any possible duties and charges. You will be solely responsible to pay any and all
          sales, use, value-added and other taxes, duties and assessments claimed or imposed by your or any governmental
          authority.
          <div className="spacerLG" />
          <h3>
            <b>Prohibited Countries</b>
          </h3>
          <div className="spacerSM" />
          By purchasing a Colorverse-Color token from the Colorverse Smart Contract you confirm that you are not a
          citizen or resident of the United States of America, Switzerland nor a resident of a geographic area that is
          subject to UN-, US-, EU- or Swiss sanctions or embargoes, including: Afghanistan, Albania, Belarus, Bosnia &
          Herzegovina, Burundi, Central African Republic, Cote d’Ivoire, Cuba, Democratic Republic of the Congo,
          Ethiopia, Guinea, Guinea-Bissau, Iran, Iraq, Lebanon, Liberia, Libya, Myanmar (Burma), North Korea, Republic
          of Macedonia (FYROM), Serbia, Somalia, South Sudan, Sri Lanka, Sudan, Syria, Thailand, Trinidad & Tobago,
          Tunisia, Ugand Uganda, Ukraine, Venezuela, Yemen, and Zimbabwe. By entering or using the website, you accept
          you represent and warrant that you are not resident in those countries.
          <div className="spacerLG" />
          <h3>
            <b>Disclaimer</b>
          </h3>
          <div className="spacerSM" />
          The information contained on this website is not intended for individuals or entities who are ordinarily
          resident in the United States of America, Switzerland nor for residents of a geographic area that is subject
          to UN-, US-, EU- or Swiss sanctions or embargoes, including: Afghanistan, Albania, Belarus, Bosnia &
          Herzegovina, Burundi, Central African Republic, Cote d’Ivoire, Cuba, Democratic Republic of the Congo,
          Ethiopia, Guinea, Guinea-Bissau, Iran, Iraq, Lebanon, Liberia, Libya, Myanmar (Burma), North Korea, Republic
          of Macedonia (FYROM), Serbia, Somalia, South Sudan, Sri Lanka, Sudan, Syria, Thailand, Trinidad & Tobago,
          Tunisia, Uganda, Ukraine, Venezuela, Yemen, and Zimbabwe. By entering or using the website, you accept you
          represent and warrant that you are not resident in those countries. The project team behind Colorverse
          (hereafter Colorverse) reserves the right to restrict the sale of the NFT token through colorverse.io in any
          jurisdiction or to any individuals or entities at its discretion. Colorverse provides the website and its
          connected services “as is” and “as available,” without warranty of any kind. Without limiting this, we
          expressly disclaim all warranties, whether expressed, implied or statutory, regarding the website. Using
          applications on the blockchain is associated with a high degree of technological and/or other risks. Holders
          and/or purchasers of the NFT-Tokens and associated content and artwork are subject to the Terms and Conditions
          available on this website. The NFT-Tokens are non-fungible tokens representing ownership of digital
          content/artwork only. Accordingly, no information on this website (or any other documents mentioned therein)
          is or may be considered to be advice or an invitation to enter into an agreement for any investment purpose.
          Further, as NFT-Tokens represent content/artwork, nothing on this website qualifies, or is intended to be an
          offering of securities in any jurisdiction, nor does it constitute an offer or an invitation to purchase
          shares, securities or any other financial products. Due to the artistic and experimental nature of this
          project, neither this document nor the NFT-Tokens have been registered with, or approved by any regulator in
          any jurisdiction. It remains the purchaser’s sole responsibility to assure that the purchase of the NFT-Tokens
          and the associated art is in compliance with laws and regulations in your jurisdiction. Colorverse undertakes
          no obligation to publicly update or revise any information or opinions published on the website. Colorverse
          reserves the right to amend the information at any time without prior notice.
        </p>
        <div className="spacerLG" />
        <div className="spacerLG" />
        <div className="spacerLG" />
        <div className="spacerLG" />
      </div>
    </div>

    /* <div key="style1">
<div key="style" className="container" className="rowsSubpage">
  <h1>
    <b>#FF00DD</b> This is <b>Colorverse</b> and an h1 title.
  </h1>{" "}
  <div className="spacerLG" />
  <h2>
    <b>#FF00DD</b> This is <b>Colorverse</b> and an h2 title.
  </h2>{" "}
  <div className="spacerLG" />
  <h3>
    <b>#FF00DD</b> This is <b>Colorverse</b> and an h3 title.
  </h3>{" "}
  <div className="spacerLG" />
  <h4>
    <b>#FF00DD</b> This is <b>Colorverse</b> and an h4 title.
  </h4>{" "}
  <div className="spacerLG" />
  <p>
    <b>#FF00DD</b> This is <b>Colorverse</b> and an p body.
  </p>
  <div className="spacerLG" />
  <h5>
    <b>#FF00DD</b> This is <b>Colorverse</b> and an h5 title.
  </h5>{" "}
  <div className="spacerLG" />
  <button className="ButtonPrimary">Buy Colors</button>
  <div className="spacerLG" />
  <button className="ButtonSecondary">Buy Colors</button>
  <Row gutter={[8, 8]}>
    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="hiddenMobile">
      <div className="BigCard"></div>
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="hiddenMobile"></Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="hiddenDesktop"></Col>
  </Row>
</div>
</div> */
  );
}
