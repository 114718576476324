import { Menu } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../AppContextWrapper";
import MenuColours from "./MenuColours";
import { useTranslation, Trans } from "react-i18next";

const DesktopMenu = ({ route, setRoute }) => {
  const { provider, address } = useAppContext();
  const { t } = useTranslation();

  return (
    <div key="menu">
      <Menu
        className="desktopNav"
        style={{ textAlign: "center", position: "fixed" }}
        selectedKeys={[route]}
        mode="horizontal"
      >
        <Menu.Item key="/search">
          <Link
            onClick={() => {
              setRoute("/search");
            }}
            to="/search"
          >
            {t("search")}
          </Link>
        </Menu.Item>

        {/* <div style={{ display: address ? "block" : "none" }} key="/MyColors"> */}
        <Menu.Item key="/MyColors">
          <Link
            onClick={() => {
              setRoute("/MyColors");
            }}
            to="/MyColors"
          >
            {t("my_colors")}
          </Link>
        </Menu.Item>
        {/* </div> */}

        <Menu.Item key="/founder">
          <Link
            onClick={() => {
              setRoute("/founder");
            }}
            to="/founder"
          >
            {t("founder_gallery")}
          </Link>
        </Menu.Item>
        <Menu.Item key="/NFWe">
          <Link
            onClick={() => {
              setRoute("/NFWe");
            }}
            to="/NFWe"
          >
            NFWe
          </Link>
        </Menu.Item>

        {/* <Menu.Item key="/colorbasket" icon={<MenuColours />}>
          <Link
            onClick={() => {
              setRoute("/colorbasket");
            }}
            to="/colorbasket"
          >
            {t("basket")}
          </Link>
        </Menu.Item> */}
      </Menu>
    </div>
  );
};

export default DesktopMenu;
