import React, { useEffect } from "react";
import { Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useTranslation, Trans, initReactI18next } from "react-i18next";
import { ItemsIntro, LandingPageLastMinted } from "../components";
import { useContractReader } from "../hooks";
import { useAppContext } from "../AppContextWrapper";

const MintedColorSentence = ({ totalSupply }) => {
  const { bitColors } = useAppContext();
  const history = useHistory();
  const { t } = useTranslation();

  return typeof totalSupply === "undefined" ? (
    <>
      {/* {t("pure_shades_of_red")}&nbsp; */}
      {/* <b> {t("still_a_few_left")}</b> */}
      {t("total_colors")} <b>16,777,216</b>.
    </>
  ) : (
    <>
      {/* {t("pure_shades_of_red")}&nbsp;
      <a onClick={() => history.push(`/specialcolors`)} style={{ borderBottom: "solid 2px #ffffff" }}>
        <b>{t("of_256_available", { bitColors })}</b>
      </a>
      <br /> */}
      {/* {t("of_16777216_sold_en")} */}
      <b>{totalSupply}</b> of 16,777,216 colors sold.
      {/* {t("of_16777216_sold_cn")} */}
    </>
  );
};

const CallToAction = () => {
  const history = useHistory();
  const { connect, provider } = useAppContext();
  const { t } = useTranslation();

  return provider ? (
    <button onClick={() => history.push("/search")} className="ButtonPrimary" key="getyoursbutton">
      {t("get_your_colors")}
    </button>
  ) : (
    <>
      <button onClick={connect} className="ButtonPrimary">
        {t("connect_metamask")}
      </button>
      <div className="spacerSM" />
      <h5>
        <a href="https://metamask.io/" className="smallLink" style={{ marginTop: "16px", marginLeft: "24px" }}>
          {t("get_metamask")} <ArrowRightOutlined />
        </a>
      </h5>
    </>
  );
};

const IntroQuery = gql`
  query IntroQuery {
    contract(id: "0xfee27fb71ae3feeef2c11f8e02037c42945e87c4") {
      totalSupply
    }
    transfers(
      where: { from: "0x0000000000000000000000000000000000000000" }
      orderBy: timestamp
      orderDirection: desc
      first: 5
    ) {
      id
      token {
        id
      }
      to {
        id
      }
    }
    shadesOfRed: tokens(where: { green: 0, blue: 0 }, first: 500) {
      id
      red
      blue
      green
    }
  }
`;

export default function Intro({ backgroundColor, setBackgroundColor }) {
  const { setBitColors, readContracts, provider } = useAppContext();
  const { loading, error, data } = useQuery(IntroQuery);

  const totalSupply = data?.contract?.totalSupply;
  const lastMinted = data?.transfers?.[0];

  console.log({ data });

  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  const bitcolorsixtyfour = [
    "010000",
    "020000",
    "030000",
    "040000",
    "050000",
    "060000",
    "070000",
    "080000",
    "090000",
    "0a0000",
    "0b0000",
    "0c0000",
    "0d0000",
    "0e0000",
    "0f0000",

    "100000",
    "110000",
    "120000",
    "130000",
    "140000",
    "150000",
    "160000",
    "170000",
    "180000",
    "190000",
    "1a0000",
    "1b0000",
    "1c0000",
    "1d0000",
    "1e0000",
    "1f0000",

    "200000",
    "210000",
    "220000",
    "230000",
    "240000",
    "250000",
    "260000",
    "270000",
    "280000",
    "290000",
    "2a0000",
    "2b0000",
    "2c0000",
    "2d0000",
    "2e0000",
    "2f0000",

    "300000",
    "310000",
    "320000",
    "330000",
    "340000",
    "350000",
    "360000",
    "370000",
    "380000",
    "390000",
    "3a0000",
    "3b0000",
    "3c0000",
    "3d0000",
    "3e0000",
    "3f0000",

    "400000",
    "410000",
    "420000",
    "430000",
    "440000",
    "450000",
    "460000",
    "470000",
    "480000",
    "490000",
    "4a0000",
    "4b0000",
    "4c0000",
    "4d0000",
    "4e0000",
    "4f0000",

    "500000",
    "510000",
    "520000",
    "530000",
    "540000",
    "550000",
    "560000",
    "570000",
    "580000",
    "590000",
    "5a0000",
    "5b0000",
    "5c0000",
    "5d0000",
    "5e0000",
    "5f0000",

    "600000",
    "610000",
    "620000",
    "630000",
    "640000",
    "650000",
    "660000",
    "670000",
    "680000",
    "690000",
    "6a0000",
    "6b0000",
    "6c0000",
    "6d0000",
    "6e0000",
    "6f0000",

    "700000",
    "710000",
    "720000",
    "730000",
    "740000",
    "750000",
    "760000",
    "770000",
    "780000",
    "790000",
    "7a0000",
    "7b0000",
    "7c0000",
    "7d0000",
    "7e0000",
    "7f0000",

    "800000",
    "810000",
    "820000",
    "830000",
    "840000",
    "850000",
    "860000",
    "870000",
    "880000",
    "890000",
    "8a0000",
    "8b0000",
    "8c0000",
    "8d0000",
    "8e0000",
    "8f0000",

    "900000",
    "910000",
    "920000",
    "930000",
    "940000",
    "950000",
    "960000",
    "970000",
    "980000",
    "990000",
    "9a0000",
    "9b0000",
    "9c0000",
    "9d0000",
    "9e0000",
    "9f0000",

    "a00000",
    "a10000",
    "a20000",
    "a30000",
    "a40000",
    "a50000",
    "a60000",
    "a70000",
    "a80000",
    "a90000",
    "aa0000",
    "ab0000",
    "ac0000",
    "ad0000",
    "ae0000",
    "af0000",

    "b00000",
    "b10000",
    "b20000",
    "b30000",
    "b40000",
    "b50000",
    "b60000",
    "b70000",
    "b80000",
    "b90000",
    "ba0000",
    "bb0000",
    "bc0000",
    "bd0000",
    "be0000",
    "bf0000",

    "c00000",
    "c10000",
    "c20000",
    "c30000",
    "c40000",
    "c50000",
    "c60000",
    "c70000",
    "c80000",
    "c90000",
    "ca0000",
    "cb0000",
    "cc0000",
    "cd0000",
    "ce0000",
    "cf0000",

    "d00000",
    "d10000",
    "d20000",
    "d30000",
    "d40000",
    "d50000",
    "d60000",
    "d70000",
    "d80000",
    "d90000",
    "da0000",
    "db0000",
    "dc0000",
    "dd0000",
    "de0000",
    "df0000",

    "e00000",
    "e10000",
    "e20000",
    "e30000",
    "e40000",
    "e50000",
    "e60000",
    "e70000",
    "e80000",
    "e90000",
    "ea0000",
    "eb0000",
    "ec0000",
    "ed0000",
    "ee0000",
    "ef0000",

    "f00000",
    "f10000",
    "f20000",
    "f30000",
    "f40000",
    "f50000",
    "f60000",
    "f70000",
    "f80000",
    "f90000",
    "fa0000",
    "fb0000",
    "fc0000",
    "fd0000",
    "fe0000",
    "ff0000",
  ];
  const hexToDecimal = hex => parseInt(hex.substr(0), 16);

  const bits = [];

  const getBalance = async color => {
    if (readContracts) {
      const exist = await readContracts.Colorverse.exists(hexToDecimal(color));
      if (!exist) {
        bits.push(color);
        setBitColors(bits.length);
      }
    }
  };

  useEffect(() => {
    bitcolorsixtyfour.map(getBalance);
  }, [readContracts]);

  const number = new Date();

  return (
    <div className="container mainpage" key="intro">
      <div style={{ display: provider ? "block" : "none" }}>{/* <ItemsStart /> */}</div>
      {/* DESKTOP */}

      <Row gutter={[24, 24]} className="rows hiddenMobile">
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="column hiddenMobile">
          {provider ? (
            <div className="left " onClick={() => history.push("/search")} key="buttonToSearch">
              <ItemsIntro backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor} />
            </div>
          ) : (
            <div className="right">
              <div className="intro start noscroll">
                <h2>
                  <b className="logofont">Colorverse</b>
                </h2>
              </div>
            </div>
          )}
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="column">
          <div className="right">
            <div className="intro start">
              <h2>
                <b>{t("all_colors_tokenized")} </b>
                <br />
                {t("find_your_colors")}

                <br />
                {t("own_your_colors")}
                <div style={{ marginBottom: "15px" }} />
              </h2>
              <div style={{ marginBottom: "15px" }}>
                <Trans i18nKey="the_first_meta_nft_for_generative_art">
                  The <b>first meta-NFT</b> for generative art.
                  <br />
                  <b>100% on-chain.</b>
                </Trans>
              </div>
              <MintedColorSentence totalSupply={totalSupply} />
              <br />
              {/* {t("get_your")}NFWe&nbsp;
              <a onClick={() => history.push(`/NFWe`)} style={{ borderBottom: "solid 2px #ffffff" }}>
                <b>{t("here")}</b>.
              </a> */}
              <div className="spacerSM" />
              <div>
                <CallToAction />
              </div>
              {lastMinted && (
                <>
                  <br />
                  <p>Last minted color: </p> <br />
                  <LandingPageLastMinted
                    hex={lastMinted.token.id.replace("0x", "").toUpperCase()}
                    string={lastMinted.token.id.replace("0x", "#")}
                    contrast={lastMinted.token.id.replace("0x", "#")}
                    id={lastMinted.token.id.replace("0x", "#")}
                    key={lastMinted.token.id.replace("0x", "#")}
                    holderaddress={lastMinted.to.id}
                  />
                </>
              )}
            </div>
            <div className="hoverdots none" />

            <div />
          </div>
        </Col>
      </Row>
      {/* MOBILE */}

      <Row className="rows hiddenDesktop rowMobile">
        <Col className="ColMobile ">
          <div style={{ display: provider ? "block" : "none" }}>
            <div className="firstMobile" onClick={() => history.push("/search")} key="buttonToSearch2">
              <ItemsIntro backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor} />
            </div>
            <div style={{ display: provider ? "none" : "block" }}>
              <div className="spacerLG " />
              <div className="spacerLG " />
            </div>
          </div>
          <div className="spacerSM" />

          <div>
            <h2>
              <b> All colors. Tokenized. </b>
              <br />
              Find your colors.
              <br />
              Own your colors.
            </h2>
          </div>
          <div className="spacerSM" />

          <div style={{ marginBottom: "15px" }}>
            {" "}
            <Trans i18nKey="the_first_meta_nft_for_generative_art">
              The <b>first meta-NFT</b> for generative art.
              <br />
              <b>100% on-chain.</b>
            </Trans>
            <br />
            <MintedColorSentence totalSupply={totalSupply} />
            <br />
            {/* {t("get_your")}&nbsp;
            <a onClick={() => history.push(`/NFWe`)} style={{ borderBottom: "solid 2px #ffffff" }}>
              <b> {t("here")}</b>.
            </a> */}
          </div>

          <div>
            <CallToAction />
          </div>
          <div className="spacerLG" />

          <div className="spacerLG" />
        </Col>
      </Row>
    </div>
  );
}
